import React, { memo } from 'react'
import tb from '../../scss/tables.common.module.scss'
import cx from 'classnames'
import st from '../../scss/global.module.scss'
import { convertCoin, toMoney } from '../../helpers/helpers'
import ReactTooltip from 'react-tooltip'
import gb from '../../scss/global.module.scss'
import PropTypes from 'prop-types'

const MultiBetRowItem = memo(({ ix, bet, message }) => {
	return (
		<tr key={ix} className={tb.subTicketRow}>
			<td className={tb.edition} />
			<td className={cx(tb.outcome, tb.rowNum)}>
				<span>{ix + 1}</span>
			</td>
			<td className={tb.cashout}>
				{bet?.forcedCoef > 0 ? (
					<>
						<span
							className={st.tktCashoutLimit}
							data-tip={message}
							data-for={'tktLimitTip'}
						>
							<i className={cx('icon-attention', tb.tktLimitWarning)} />
							{bet.coef ? `${toMoney(bet.coef)}v` : '-'}
						</span>
						<ReactTooltip
							id={'tktLimitTip'}
							place={'top'}
							effect={'solid'}
							offset={{ top: '-5px' }}
							multiline={true}
							backgroundColor={'rgba(0, 0, 0, .8)'}
							html={true}
							className={gb.toolTipStyle}
						/>
					</>
				) : bet.coef ? (
					`${toMoney(bet.coef)}v`
				) : (
					'-'
				)}
			</td>
			<td className={tb.bet}>
				{bet.bet ? (
					<span>
						<i
							className={cx('icon-currency', {
								'icon-volt': bet.currencyType === 'VIRTUAL',
								'icon-gel': bet.currencyType === 'REAL',
							})}
						/>
						{convertCoin(bet.bet)}
					</span>
				) : (
					'-'
				)}
			</td>
			<td className={tb.win}>
				{bet.profit ? (
					<span>
						<i className={'icon-currency icon-gel'} />
						{convertCoin(bet.profit)}
					</span>
				) : (
					'-'
				)}
			</td>
			<td className={tb.check} />
		</tr>
	)
})

MultiBetRowItem.propTypes = {
	ix: PropTypes.number.isRequired,
	bet: PropTypes.object.isRequired,
	message: PropTypes.string.isRequired,
}

MultiBetRowItem.displayName = 'MultiBetRowItem'
export default MultiBetRowItem
