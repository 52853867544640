const actionTypes = {
	// userData
	SET_USER_DATA: 'SET_USER_DATA',

	// uiData
	SET_ACTIVE_TAB: 'SET_ACTIVE_TAB',
	CHECK_EDITION: 'CHECK_EDITION',
	SET_GRAPH_SIZE: 'SET_GRAPH_SIZE',
	SET_DUAL_SESSION: 'SET_DUAL_SESSION',
	SET_REF_BLOCK: 'SET_REF_BLOCK',
	SET_UI_ACTION: 'SET_UI_ACTION',

	// ticketData
	CREATE_DEFAULT_TICKET: 'CREATE_DEFAULT_TICKET',
	SET_TICKET_DATA: 'SET_TICKET_DATA',
	SET_TICKET_BET: 'SET_TICKET_BET',
	SET_TICKET_CASHOUT: 'SET_TICKET_CASHOUT',
	CLEAN_TICKET_INPUTS: 'CLEAN_TICKET_INPUTS',
	SUBMIT_BET_SHORTCUT: 'SUBMIT_BET_SHORTCUT',
	SUBMIT_CASHOUT_SHORTCUT: 'SUBMIT_CASHOUT_SHORTCUT',
	SET_AUTO_BET: 'SET_AUTO_BET',
	SET_AUTO_CASHOUT: 'SET_AUTO_CASHOUT',
	ADD_TICKET: 'ADD_TICKET',
	REMOVE_TICKET: 'REMOVE_TICKET',
	SUBMIT_TICKET: 'SUBMIT_TICKET',
	CANCEL_TICKET: 'CANCEL_TICKET',
	PLACE_BET: 'PLACE_BET',
	USER_BET_PLACED: 'USER_BET_PLACED',
	CRASH_TICKETS: 'CRASH_TICKETS',
	SUBMIT_TICKETS_ALL: 'SUBMIT_TICKETS_ALL',
	CANCEL_TICKETS_ALL: 'CANCEL_TICKETS_ALL',
	CASHOUT: 'CASHOUT',
	CASHOUT_ALL: 'CASHOUT_ALL',
	REVIEW_TICKETS_ON_AUTH: 'REVIEW_TICKETS_ON_AUTH',
	TRANSFORM_FREESPIN_TICKETS: 'TRANSFORM_FREESPIN_TICKETS',
	TKT_STEP_CHANGE: 'TKT_STEP_CHANGE',

	// chatData
	SET_CHAT_DATA: 'SET_CHAT_DATA',
	CLOSE_CHAT: 'CLOSE_CHAT',
	OPEN_CHAT: 'OPEN_CHAT',
	TOGGLE_INPUT_LANG: 'TOGGLE_INPUT_LANG',

	// jackpot
	SET_JACKPOT: 'SET_JACKPOT',
	SET_JACKPOT_DROP: 'SET_JACKPOT_DROP',
	SET_JACKPOT_ANIMATION: 'SET_JACKPOT_ANIMATION',

	// gifData
	SET_GIF_LOADING: 'SET_GIF_LOADING',
	FETCH_GIFS: 'FETCH_GIFS',
	GIF_FIRST_PAGE: 'GIF_FIRST_PAGE',
	GIF_PREV_PAGE: 'GIF_PREV_PAGE',
	GIF_NEXT_PAGE: 'GIF_NEXT_PAGE',
	GIF_SEARCH: 'GIF_SEARCH',

	// gameData
	SET_GAME_STATE: 'SET_GAME_STATE',
	SET_GAME_STATE_VIRTUAL: 'SET_GAME_STATE_VIRTUAL',
	SET_HISTORY: 'SET_HISTORY',
	SET_BETTORS: 'SET_BETTORS',
	RESET_BETTORS: 'RESET_BETTORS',
	SET_LIMITS: 'SET_LIMITS',

	// modals
	OPEN_MODAL: 'OPEN_MODAL',
	CLOSE_MODAL: 'CLOSE_MODAL',

	// mobile
	SET_NAV_OPEN: 'SET_NAV_OPEN',
}

export default actionTypes
