const config = {
	devMode: true,
	proxyProd: true,
	devServer: 'http://10.0.2.17',
	prodServer: 'http://10.0.22.93',
	ports: {
		api: 8001,
		chat: 8080,
		stats: 9292,
		jackpot: 9097,
	},
	endpoints: {
		api: '/api/rsocket',
		chat: '/chat/rsocket',
		login: '/api/login',
		exit: '/api/exit',
	},

	get server() {
		return this.proxyProd ? this.prodServer : this.devServer
	},

	get apiEndpoint() {
		return `${this.server}:${this.ports.api}`
	},

	get chatEndpoint() {
		return `${this.server}:${this.ports.chat}`
	},
}

exports['config'] = config
