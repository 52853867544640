import React, {
	createRef,
	Fragment,
	useEffect,
	useMemo,
	useRef,
	useState,
} from 'react'
import Tab from './Tab'
import st from './Tabs.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import cx from 'classnames'
import { useResizeDetector } from 'react-resize-detector'
import { getLocalStorage, setLocalStorage } from '../../helpers/helpers'
import { checkEdition, setActiveTab } from '../../store/actions/baseActions'
import useTimeout from '../../hooks/useTimeout'

const Tabs = (props) => {
	const dispatch = useDispatch()
	const [widths, setWidths] = useState([])
	// eslint-disable-next-line react-hooks/exhaustive-deps
	const items = useMemo(
		() => props.children.filter((itm) => itm.type === Tab),
		[],
	)
	const tabRefs = useRef(items.map(() => createRef()))
	const [activeTabProps, setActiveTabProps] = useState()
	const { width: wrapperWidth, ref: wrapperRef } = useResizeDetector({
		refreshMode: 'debounce',
		refreshRate: 50,
	})
	const activeTab = useSelector((state) => state?.activeTabId)
	const currentEdition = useSelector((state) => state?.gameState.editionId)

	useEffect(() => {
		const index = items.findIndex((itm) => itm.props.id === activeTab)
		setActiveTabProps(widths[index])
	}, [dispatch, activeTab, widths, items])

	// fix initial tab width after styles rendered
	useTimeout(() => {
		const nextWidths = tabRefs.current.map((ref) =>
			ref.current.getBoundingClientRect(),
		)
		setWidths(nextWidths)
	}, 300)

	// update width on resize
	useEffect(() => {
		const nextWidths = tabRefs.current.map((ref) =>
			ref.current.getBoundingClientRect(),
		)
		setWidths(nextWidths)
	}, [wrapperWidth])

	// get active tab from storage on first load
	useEffect(() => {
		const storedTab = getLocalStorage('activeTab')
		if (storedTab) {
			dispatch(setActiveTab(storedTab))
		}
	}, [dispatch])

	// save active tab in storage
	useEffect(() => {
		setLocalStorage('activeTab', activeTab)
	}, [activeTab])

	return (
		<Fragment>
			{/*<nav className={cx(st.tabItems, st.promo)} ref={wrapperRef}>*/}
			<nav className={cx(st.tabItems)} ref={wrapperRef}>
				{items.map((child, index) => {
					return (
						<span
							className={cx(st.tabItem, {
								[st.active]: activeTab === child.props.id,
							})}
							ref={tabRefs.current[index]}
							key={index}
							onClick={() => {
								if (child.props.id === 'check') {
									dispatch(
										checkEdition({
											editionId: currentEdition,
											tabId: 'check',
										}),
									)
								} else {
									dispatch(setActiveTab(child.props.id))
								}
							}}
						>
							<span className={st.tabText}>{child.props.title}</span>
							{child.props.icon && (
								<i
									title={child.props.title}
									className={`icon-${child.props.icon}`}
								/>
							)}
						</span>
					)
				})}
				{props.children.length && (
					<span
						className={st.tabActiveIndicator}
						style={{
							width: activeTabProps?.width,
							left: activeTabProps ? activeTabProps?.left - 10 : 0,
						}}
					/>
				)}

				<a
					className="promoButton"
					href="//www.lider-bet.com/web/ka/promotions?page=playVolt2"
					target="_blank"
					rel="noreferrer"
				>
					PROMO
				</a>
			</nav>
			<div className={st.tabContents}>
				{items.map((child, index) => (
					<div
						key={index}
						className={cx(st.tabContent, {
							[st.active]: child.props.id === activeTab,
						})}
					>
						{child.props.children}
					</div>
				))}
			</div>
		</Fragment>
	)
}

export default Tabs
