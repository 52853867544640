import actionTypes from '../types/actionTypes'
import {
	callAuthWindow,
	checkTicketLimits,
	convertCoin,
	displayError,
	fancyInputs,
	getLocalStorage,
	getUid,
	moneyInputClean,
	setLocalStorage,
	stepChange,
} from '../../helpers/helpers'
import { gameStates, ticketStates, ToastID } from '../types'
import service from '../../api/services'

const ticketDataReducer = {
	[actionTypes.CREATE_DEFAULT_TICKET]: (state) => {
		// get stored tickets
		let tickets = getLocalStorage('tickets')
		if (tickets?.length) {
			state.tickets = tickets.map((tkt) => ({
				...tkt,
				state: ticketStates.TICKET_IDLE,
				autoBet: false,
			}))
		} else {
			state.tickets = [
				{
					...state.defaultTicket,
					autoBet: false,
					id: getUid(),
				},
			]
		}

		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.SET_TICKET_DATA]: (state, action) => {
		state.tickets = state.tickets.map((tkt) => {
			if (tkt.id === action.payload.id) {
				tkt = {
					...tkt,
					...action.payload,
				}
			}
			return tkt
		})
		// store tickets
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.SET_TICKET_BET]: (state, action) => {
		state.tickets = state.tickets.map((tkt) => {
			if (tkt.id === action.payload.id) {
				tkt = {
					...tkt,
					bet: moneyInputClean(action.payload.bet),
				}
			}
			return tkt
		})
		// store tickets
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.SET_TICKET_CASHOUT]: (state, action) => {
		state.tickets = state.tickets.map((tkt) => {
			if (tkt.id === action.payload.id) {
				tkt = {
					...tkt,
					cashout: moneyInputClean(action.payload.cashout),
					autoCashout: true,
				}
			}
			return tkt
		})
		// store tickets
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.CLEAN_TICKET_INPUTS]: (state, action) => {
		state.tickets = state.tickets.map((tkt) => {
			if (tkt.id === action.payload.id) {
				tkt = {
					...tkt,
					bet: fancyInputs(tkt.bet, 'bet', tkt, state.limits.maxTotalBet),
					cashout: !tkt.autoCashout
						? '--'
						: fancyInputs(
								tkt.cashout,
								'cashout',
								tkt,
								state.limits.maxMultiplierPerTicket,
						  ),
				}
			}
			return tkt
		})
		// store tickets
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.SUBMIT_BET_SHORTCUT]: (state, action) => {
		if (state.userdata.auth) {
			const realBal = parseFloat(convertCoin(state.userdata.balance.real))
			const virtualBal = parseFloat(
				convertCoin(state.userdata.balance.virtual),
			)

			state.tickets = state.tickets.map((tkt) => {
				if (tkt.id === action.payload.id) {
					let value = action.payload.bet

					if (value === 'x2') {
						value = parseFloat(tkt.bet * 2)
					}
					if (value === 'all') {
						value = state.limits.maxTotalBet
					}

					// check balance
					value = tkt.std
						? Math.min(value, realBal)
						: Math.min(value, virtualBal)

					// check max limits
					value = Math.min(value, state.limits.maxTotalBet)

					tkt = {
						...tkt,
						bet: fancyInputs(moneyInputClean(value), 'bet', tkt),
					}
				}

				return tkt
			})
			// store tickets
			if (state.userdata.auth) {
				setLocalStorage('tickets', state.tickets)
			}
		} else {
			callAuthWindow()
		}
	},
	[actionTypes.SUBMIT_CASHOUT_SHORTCUT]: (state, action) => {
		state.tickets = state.tickets.map((tkt) => {
			let value = action.payload.cashout
			if (tkt.id === action.payload.id && typeof value === 'number') {
				tkt = {
					...tkt,
					cashout: fancyInputs(
						moneyInputClean(value),
						'cashout',
						tkt,
						state.limits.maxMultiplierPerTicket,
					),
					autoCashout: true,
				}
			}
			return tkt
		})
		// store tickets
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.SET_AUTO_BET]: (state, action) => {
		state.tickets = state.tickets.map((tkt) => {
			if (tkt.id === action.payload.id) {
				tkt = {
					...tkt,
					autoBet: action.payload.autoBet,
				}
			}
			return tkt
		})
		// store tickets
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.SET_AUTO_CASHOUT]: (state, action) => {
		state.tickets = state.tickets.map((tkt) => {
			if (tkt.id === action.payload.id) {
				tkt = {
					...tkt,
					autoCashout: action.payload.autoCashout,
				}

				// auto-cashout off
				if (!action.payload.autoCashout) {
					tkt.cashout = '--'
				} else {
					if (tkt.cashout === '--') {
						tkt.cashout = '10.00'
					}
				}
			}
			return tkt
		})

		// store tickets
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.ADD_TICKET]: (state, { payload }) => {
		if (state.tickets.length >= state.limits.maxTicketCount) {
			displayError(ToastID.MAX_TICKETS, state.limits.maxTicketCount)
		} else {
			const lastTicket = state.tickets[state.tickets.length - 1]
			state.tickets.push({
				...lastTicket,
				id: getUid(),
				state: ticketStates.TICKET_IDLE,
				std: !payload?.virtual,
			})
		}

		// store tickets
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.REMOVE_TICKET]: (state, action) => {
		state.tickets = state.tickets.filter(
			(tkt) => tkt.id !== action.payload.id,
		)

		// store tickets
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.SUBMIT_TICKET]: (state, action) => {
		if (checkTicketLimits(state, action.payload)) {
			state.tickets = state.tickets.map((tkt) => {
				if (tkt.id === action.payload.id) {
					tkt.state = ticketStates.TICKET_SUBMITTED
				}
				return tkt
			})
		}
	},
	[actionTypes.CANCEL_TICKET]: (state, action) => {
		state.tickets = state.tickets.map((tkt) => {
			if (tkt.id === action.payload.id) {
				tkt.state = ticketStates.TICKET_IDLE
			}
			return tkt
		})
	},
	[actionTypes.CANCEL_TICKET]: (state, action) => {
		state.tickets = state.tickets.map((tkt) => {
			if (tkt.id === action.payload.id) {
				tkt.state = ticketStates.TICKET_IDLE
			}
			return tkt
		})
	},
	[actionTypes.USER_BET_PLACED]: (state, action) => {
		const bets = action.payload.bets
		state.tickets = state.tickets.map((tkt) => {
			const tktBetData = bets.filter((bet) => bet.cId === tkt.id)
			if (tktBetData.length > 0) {
				tkt.sid = tktBetData[0].id
				tkt.state = ticketStates.TICKET_ACCEPTED
			}
			return tkt
		})

		// store tickets
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.CRASH_TICKETS]: (state) => {
		state.tickets = state.tickets.map((tkt) => {
			switch (tkt.state) {
				case ticketStates.TICKET_ACCEPTED:
					if (tkt.autoBet) {
						tkt.state = ticketStates.TICKET_SUBMITTED
					} else {
						tkt.state = ticketStates.TICKET_IDLE
					}
					break
				default:
					break
			}
			return tkt
		})
	},
	[actionTypes.SUBMIT_TICKETS_ALL]: (state) => {
		if (state.userdata.auth) {
			// check bet limits
			let totalStd = 0,
				totalVirtual = 0,
				totalBet

			for (let tkt of state.tickets) {
				totalStd += tkt.std ? parseFloat(tkt.bet) : 0
				totalVirtual += tkt.std ? 0 : parseFloat(tkt.bet)
			}

			totalBet = totalStd + totalVirtual

			if (totalBet > state.limits.maxTotalBet) {
				displayError(ToastID.MAX_TOTAL_BET, state.limits.maxTotalBet)
			} else {
				// check std balance
				if (
					totalStd > parseFloat(convertCoin(state.userdata.balance.real))
				) {
					displayError(ToastID.BALANCE_NOT_ENOUGH)
				} else if (
					totalVirtual >
					parseFloat(convertCoin(state.userdata.balance.virtual))
				) {
					displayError(ToastID.BALANCE_NOT_ENOUGH_VIRTUAL)
				} else {
					state.tickets = state.tickets.map((tkt) => {
						if (tkt.state === ticketStates.TICKET_IDLE) {
							tkt.state = ticketStates.TICKET_SUBMITTED
						}
						return tkt
					})
				}
			}
		} else {
			callAuthWindow()
		}
	},
	[actionTypes.CANCEL_TICKETS_ALL]: (state) => {
		state.tickets = state.tickets.map((tkt) => {
			if (tkt.state === ticketStates.TICKET_SUBMITTED) {
				tkt.state = ticketStates.TICKET_IDLE
			}

			// disable auto-bet
			tkt.autoBet = false
			return tkt
		})
	},
	[actionTypes.CASHOUT]: (state, action) => {
		if (action.payload.length) {
			// disable auto-bet
			state.tickets = state.tickets.map((tkt) => {
				if (tkt.sid === action.payload?.[0]) {
					tkt.autoBet = false
				}
				return tkt
			})

			// save tickets to local storage
			if (state.userdata.auth) {
				setLocalStorage('tickets', state.tickets)
			}

			// request cashout
			service.cashout(state.gameState.editionId, action.payload)
		}
	},
	[actionTypes.CASHOUT_ALL]: (state) => {
		const betIds = state.tickets
			.filter((tkt) => tkt.state === ticketStates.TICKET_ACCEPTED)
			.map((tkt) => tkt.sid)
		if (state.gameState.state === gameStates.ST_GO_GO && betIds.length) {
			service.cashout(state.gameState.editionId, betIds)
		}
	},
	[actionTypes.REVIEW_TICKETS_ON_AUTH]: (state) => {
		// remove free-bet tickets if
		if (state.userdata.auth) {
			if (state.userdata.balance.virtual < 0.01) {
				state.tickets = state.tickets.filter((tkt) => tkt.std)
				setLocalStorage('tickets', state.tickets)
			} else {
				let tickets = getLocalStorage('tickets')
				if (tickets?.length) {
					state.tickets = tickets.map((tkt) => ({
						...tkt,
						state: ticketStates.TICKET_IDLE,
						autoBet: false,
					}))
				}
			}
		} else {
			state.tickets = state.tickets.filter((tkt) => tkt.std)
		}
	},
	[actionTypes.TRANSFORM_FREESPIN_TICKETS]: (state) => {
		// check freespin balance
		if (
			parseFloat(convertCoin(state.userdata.balance.virtual)) <
			state.limits.minBetPerTicket
		) {
			state.tickets.filter((tkt) => !tkt.std).map((tkt) => (tkt.std = true))
		}

		// save tickets to local storage
		if (state.userdata.auth) {
			setLocalStorage('tickets', state.tickets)
		}
	},
	[actionTypes.TKT_STEP_CHANGE]: (state, action) => {
		for (let tkt of state.tickets) {
			if (tkt.id === action.payload.id) {
				if (action.payload.type === 'bet') {
					tkt.bet = stepChange(
						tkt.bet,
						action.payload.action,
						state.limits.minBetPerTicket,
						state.limits.maxTotalBet,
					)
				} else if (action.payload.type === 'cashout') {
					tkt.cashout = stepChange(
						tkt.cashout,
						action.payload.action,
						1.01,
						state.limits.maxMultiplierPerTicket,
						action.payload.type,
					)
				}
				break
			}
		}
	},
}

export default ticketDataReducer
