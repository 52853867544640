import st from './Footer.module.scss'
import { version } from '../../../package.json'
import { openModal } from '../../store/actions/baseActions'
import { useDispatch } from 'react-redux'
import { memo } from 'react'

const Footer = memo(() => {
	const dispatch = useDispatch()

	return (
		<div className={st.footer}>
			<div className={st.copyright}>
				<a href="http://lmnt.io" target="_blank" rel="noreferrer">
					lmnt.io
				</a>
			</div>
			<div
				className={st.version}
				onClick={() => dispatch(openModal('changeLog'))}
			>
				v{version}
			</div>
		</div>
	)
})

export default Footer
