import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.min.css'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
	BatchBetButton,
	Content,
	Header,
	Jackpots,
	SideNav,
} from './components/Mobile/Mobile'
import {
	CheckScreen,
	HistoryScreen,
	Main,
} from './components/Mobile/Screens/Screens'
import React, { useCallback, useEffect, useRef } from 'react'
import { userLogin } from './store/actions/apiActions'
import { reviewTicketsOnAuth } from './store/actions/ticketActions'
import 'scss/Mobile.scss'
import st from 'scss/Mobile.module.scss'
import cx from 'classnames'
import { Route, Routes } from 'react-router-dom'
import Guide from './components/Guide/Guide'
import Modals from './components/Modals'
import ModalJackpot from './components/Modals/ModalJackpot'
import { ToastContainer } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import { registerJackpotSound } from './helpers/helpers'
import jackpotAudioFile from './sounds/jackpot.mp3'

const MobileApp = () => {
	const contentRef = useRef()
	const wrapperRef = useRef()
	const innerRef = useRef()
	const userData = useSelector((state) => state?.userdata, shallowEqual)
	const isNavOpen = useSelector((state) => state?.mobile.isNavOpen)
	const dualSession = useSelector((state) => state?.dualSession)
	const dispatch = useDispatch()
	const { t } = useTranslation()

	useEffect(() => {
		if (!userData.auth) {
			dispatch(userLogin())
		}
		// review tickets
		dispatch(reviewTicketsOnAuth())
	}, [dispatch, userData.auth])

	const heightCalc = useCallback((e) => {
		document.documentElement.style.setProperty(
			'--app-height',
			`${window.innerHeight}px`,
		)
	}, [])

	useEffect(() => {
		window.addEventListener('resize', heightCalc)
		heightCalc()

		return () => {
			window.removeEventListener('resize', heightCalc)
		}
	}, [heightCalc])

	// assign body mobile class
	useEffect(() => {
		document.body.classList.add(st.body)

		// register jackpot sound
		registerJackpotSound(jackpotAudioFile)

		return () => {
			document.body.classList.remove(st.body)
		}
	}, [])

	return (
		<>
			<div
				className={cx(st.wrapper, {
					[st.open]: isNavOpen,
					[st.closed]: !isNavOpen,
				})}
				data-page="main"
				ref={wrapperRef}
			>
				<SideNav
					wrapperRef={wrapperRef}
					contentRef={contentRef}
					userData={userData}
				/>
				<Content ref={contentRef}>
					<div className={st.inner} ref={innerRef}>
						<Header />
						<Routes basePath={'/m'}>
							<Route
								exact
								path="/"
								element={
									<>
										<Jackpots />
										<Main innerRef={innerRef} />
									</>
								}
							/>
							<Route exact path="history" element={<HistoryScreen />} />
							{userData.auth && (
								<>
									<Route
										exact
										path="check"
										element={<CheckScreen />}
									/>
									{/*<Route exact path="/stat" element={<StatScreen/>}/>*/}
								</>
							)}
							<Route
								exact
								path="guide"
								element={<Guide type={st.guide} />}
							/>
						</Routes>
					</div>
					<BatchBetButton />
				</Content>
			</div>
			<Modals>
				<ModalJackpot type={'mob'} />
			</Modals>
			<ToastContainer
				position={'top-center'}
				theme={'colored'}
				draggablePercent={10}
				toastClassName={st.toastMessage}
			/>
			{dualSession && (
				<div className={'dual-session-window'}>
					<div>{t('translation:messages.dual_session')}</div>
				</div>
			)}
		</>
	)
}

export default MobileApp
