import actionTypes from '../types/actionTypes'
import {
	bettorIndex,
	binarySearchIndex,
	convertCoin,
	toMoney,
} from '../../helpers/helpers'
import { eventTypes, ticketStates } from '../types'

const gameDataReducer = {
	[actionTypes.SET_GAME_STATE]: (state, action) => {
		state.gameState = {
			...state.gameState,
			...action.payload,
		}
	},
	[actionTypes.SET_HISTORY]: (state, action) => {
		// replace if row exists
		const replaceIndex = state.history.findIndex(
			(itm) => itm.editionId === action.payload.editionId,
		)
		if (replaceIndex > -1) {
			// if ticket event => replace or push only bets
			if (
				!action.payload.crash &&
				!action.payload.hash &&
				action.payload.bets.length
			) {
				// push or replace bets
				for (let newBet of action.payload.bets) {
					const betReplaceId = state.history[replaceIndex].bets.findIndex(
						(bet) => bet.id === newBet.id,
					)
					// replace bets
					if (betReplaceId > -1) {
						state.history[replaceIndex].bets[betReplaceId] = newBet
						// cashout ticket
						if (newBet.profit) {
							state.tickets.map((tkt) => {
								if (
									tkt.sid === newBet.id &&
									tkt.state !== ticketStates.TICKET_SUBMITTED
								) {
									// submit again if auto-cashout
									if (tkt.autoBet) {
										tkt.state = ticketStates.TICKET_SUBMITTED
									} else {
										tkt.state = ticketStates.TICKET_IDLE
									}
								}
								return tkt
							})
						}
					}
					// push new bet
					else {
						state.history[replaceIndex].bets.push(newBet)
					}
				}
			} else {
				// regular history row
				state.history[replaceIndex] = action.payload
			}
		}
		// new row push at index
		else {
			const index = binarySearchIndex(
				state.history,
				action.payload.editionId,
				0,
				state.history.length - 1,
				-1,
				'editionId',
			)
			state.history.splice(index, 0, action.payload)

			// recover game state (editionId)
			if (!action.payload.crash && !action.payload.isFuture) {
				state.gameState.editionId = action.payload.editionId
			}

			// recover current edition active tickets
			if (!action.payload.crash) {
				action.payload.bets.forEach((bet) => {
					// find ticket
					const ticketIndex = state.tickets.findIndex(
						(tkt) => tkt.sid === bet.id,
					)

					// replace ticket
					if (ticketIndex > -1) {
						if (!bet.coef) {
							state.tickets[ticketIndex] = {
								...state.tickets[ticketIndex],
								autoBet: false,
								state: ticketStates.TICKET_ACCEPTED,
								bet: convertCoin(bet.bet),
								cashout: bet.autoCashout < 0 ? '--' : toMoney(bet.autoCashout),
							}
						}
					}
					// push ticket
					else {
						// TODO: push tickets if not exists (need additional ticket data)
					}
				})
			}
		}

		if (state.history.length > 50) {
			state.history.length = 50
		}
	},
	[actionTypes.SET_BETTORS]: (state, action) => {
		switch (action.payload['eventType']) {
			case eventTypes.PLACE_BET:
			case eventTypes.RECOVERY:
				if (
					action.payload.payload.bets &&
					action.payload.payload.bets.length
				) {
					action.payload.payload.bets.forEach((bet) => {
						bet.isMine = action.payload.isMine
						bet.usrName = action.payload.payload.usrName
						let index = bettorIndex(state.bettors, bet)
						state.bettors.splice(index, 0, bet)
					})
				}
				break
			case eventTypes.CASHOUT:
				if (
					action.payload.payload.bets &&
					action.payload.payload.bets.length
				) {
					action.payload.payload.bets.forEach((bet) => {
						bet.isMine = action.payload.isMine
						bet.usrName = action.payload.payload.usrName

						// get index in existing list
						let currentIndex = state.bettors.findIndex(
							(itm) => itm.id === bet.id,
						)

						if (currentIndex > -1) {
							state.bettors.splice(currentIndex, 1)

							// find new index
							let newIndex = bettorIndex(state.bettors, bet)
							state.bettors.splice(newIndex, 0, bet)
						}
					})
				}
				break

			default:
				break
		}
	},
	[actionTypes.RESET_BETTORS]: (state) => {
		state.bettors = []
	},
	[actionTypes.SET_JACKPOT]: (state, action) => {
		state.jackpot.data = action.payload
	},
	[actionTypes.SET_JACKPOT_DROP]: (state, action) => {
		state.jackpot.drop = {
			...state.jackpot.drop,
			...action.payload,
		}
	},
	[actionTypes.SET_JACKPOT_ANIMATION]: (state, action) => {
		state.jackpot.animating = action.payload
	},
	[actionTypes.SET_LIMITS]: (state, action) => {
		state.limits = {
			...state.limits,
			...action.payload,
		}
	},

	[actionTypes.OPEN_MODAL]: (state, action) => {
		if (Object.keys(state.modals).includes(action.payload)) {
			state.modals[action.payload] = true
		}
	},
	[actionTypes.CLOSE_MODAL]: (state, action) => {
		if (Object.keys(state.modals).includes(action.payload)) {
			state.modals[action.payload] = false
		}
	},
}

export default gameDataReducer
