import actionTypes from '../types/actionTypes'

const uiDataReducer = {
	[actionTypes.SET_ACTIVE_TAB]: (state, action) => {
		state.selectedEditionId = null
		state.activeTabId = action.payload
	},
	[actionTypes.CHECK_EDITION]: (state, action) => {
		state.selectedEditionId = action.payload.editionId
		state.activeTabId = action.payload.tabId
	},
	[actionTypes.SET_GRAPH_SIZE]: (state, action) => {
		state.graph.width = action.payload.width
		state.graph.height = action.payload.height
	},
	[actionTypes.SET_DUAL_SESSION]: (state, action) => {
		state.dualSession = action.payload
	},
	[actionTypes.SET_REF_BLOCK]: (state, action) => {
		state.refs[action.payload.id] = action.payload.value
	},
	[actionTypes.SET_UI_ACTION]: (state, action) => {
		state.ui[action.payload.id] = action.payload.value
	},
}

export default uiDataReducer
