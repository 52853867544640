import st from './Guide.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { Trans, useTranslation } from 'react-i18next'
import { i18TagMap } from '../../helpers/helpers'
import { useDispatch } from 'react-redux'
import { setRefBlock } from '../../store/actions/baseActions'
import useNav from '../../hooks/useNav'
import { memo, useEffect, useRef } from 'react'

const Guide = memo(({ type }) => {
	const dispatch = useDispatch()
	const { location } = useNav()
	const jackpotRef = useRef()
	const { t } = useTranslation()

	useEffect(() => {
		if (location.hash === '#jackpot') {
			jackpotRef.current.scrollIntoView({
				behavior: 'smooth',
			})
		}
	}, [location.hash])

	return (
		<div className={cx(st.guide, type)}>
			<div className={st.guideBlock}>
				<h3>{t('common.game_name')}</h3>

				<Trans
					i18nKey={'translation:messages.guide.edition'}
					components={i18TagMap()}
				>
					<h5>ტირაჟი</h5>
					<ul>
						<li>თამაში შედგება ტირაჟებისგან.</li>
						<li>თითეულ ტირაჟს აქვს უნიკალური იდენტიფიკატორი</li>
						<li>
							ტირაჟზე ხდება კოეფიციენტის Volt (აღინიშნება სიმბოლოთი V)
							ზრდა 1 დან.
						</li>
						<li>
							ტირაჟის დასრულება/ჩაწვა ხდება კოეფიციენტზე, რომელიც
							წინასწარ არის “ჩაფიქრებული” სისტემის მიერ.
						</li>
						<li>
							ტირაჟის ჩაწვის კოეფიციენტი მომხმარებლის ქცევისგან
							დამოუკიდებელია (მასზე გავლენას არ ახდენს მიღებული ფსონები
							ან რაიმე სხვა გარემო ფაქტორები) და გამოითვლება შესაბამისი
							ალბათური განაწილების წესით.
						</li>
					</ul>
				</Trans>
			</div>

			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.ticket_types'}
					components={i18TagMap()}
				>
					<h5>ბილეთის ტიპები</h5>
					<p>თამაში შესაძლებელია განთავსდეს ორი სახის ბილეთი:</p>

					<ul>
						<li>
							სტანდარტული ბილეთი - ფსონები მიიღება მთავარ ბალანსზე
							არსებული თანხით
						</li>
						<li>
							FREEVOLT ბილეთი - ფსონები მიიღება FREEVOLT ბალანსზე
							არსებული თანხით
						</li>
					</ul>
				</Trans>
			</div>

			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.limits'}
					components={i18TagMap()}
				>
					<h5>ლიმიტები</h5>
					<p>ვოლტში მოქმედებს შემდეგი შეზღუდვები:</p>

					<ul>
						<li>მინიმალური ფსონი 1 ბილეთზე: 0.10 ლარი</li>
						<li>ბილეთების მაქსიმალური რაოდენობა 1 ტირაჟზე: 10 ბილეთი</li>
						<li>
							მაქსიმალური ჯამური ფსონი ერთ მომხმარებელზე თითოეულ ტირაჟზე:
							1000 ლარი
						</li>
						<li>ტირაჟზე მომხმარებლის მაქსიმალური მოგება: 100 000 ლარი</li>
					</ul>
				</Trans>
			</div>

			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.rounding'}
					components={i18TagMap()}
				>
					<h5>თანხების დამრგვალების წესი</h5>
					<p>
						თამაშის ფანჯარაში თანხები ნაჩვენებია ათწილადებში, სადაც
						ათწილადის ნიშანი ნაჩვენებია 2 ციფრის სიზუსტით. თამაშის
						სერვერული ნაწილი თანხებს ინახავს 4 ათწილადის ნიშნის სიზუსტით.
					</p>
				</Trans>
			</div>

			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.win'}
					components={i18TagMap()}
				>
					<h5>მოგება</h5>

					<p>
						<b>
							<u>ქეშაუთი</u>
						</b>
					</p>
					<p>
						მომხმარებელს განთავსებული ფსონის ქეშაუთი შეუძლია, ნებისმიერ
						დროს, ტირაჟის დამთავრებამდე
					</p>
					<p>ქეშაუთის კოეფიციენტი თამაშში აღინიშნება სიმბოლოთი V.</p>

					<br />
					<p>
						<b>
							<u>მოგების გამოთვლა</u>
						</b>
					</p>

					<p>
						<b>სტანდარტულ ბილეთზე</b> მოგება გამოითვლება, როგორც დადებული
						ფსონის და ქეშაუთის კოეფიციენტის ნამრავლი. მაგ. თუ ბილეთის
						ფსონი შეადგენს 10 ლარს და ქეშაუთი მოხდა 2v-ზე მოგება შეადგენს:
					</p>
					<center>
						<code>10 ლარი x 2v = 20 ლარი</code>
					</center>

					<p>
						<b>FREEVOLT ბილეთზე</b> მოგება გამოითვლება, როგორ დადებული
						ფსონის და ქეშაუთის კოეფიციენტის ნამრავლს გამოკლებული საწყისი
						ფსონი. მაგ. თუ ბილეთის ფსონი შეადგენს 10 FREEVOLT-ს და ქეშაუთი
						მოხდა 2v-ზე მოგება შეადგენს:
					</p>
					<center>
						<code>10 FREEVOLT x 2v - 10 FREEVOLT = 10 ლარი</code>
					</center>

					<br />
					<p id="maxWinRule">
						<b>
							<u>ტირაჟზე მომხმარებლის მაქსიმალური მოგების ლიმიტი</u>
						</b>
					</p>
					<p>
						როდესაც ჯამური მოგება (ტირაჟზე დადებული ბილეთების მოგების
						ჯამი) მიაღწევს ტირაჟზე მომხმარებლის მაქსიმალური მოგების ლიმიტს
						- ბილეთების ქეშაუთი ხდება ავტომატურად.
					</p>
				</Trans>
			</div>

			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.auto_cashout'}
					components={i18TagMap()}
				>
					<h5>ავტო-ქეშაუთი</h5>

					<p>
						ავტო-ქეშაუთი მომხმარებელს აძლევს შესაძლებლობას წინასწარ
						განსაზღვროს სასურველი ქეშაუთის კოეფიციენტი. როდესაც
						კოეფიციენტი მიაღწევს მითითებულ მნიშვნელობას ბილეთის ქეშაუთი
						მოხდება ავტომატურად.
					</p>
					<p>
						ავტო-ქეშაუთი მოქმედებს მაშინაც, როცა მომხმარებელმა გარკვეული
						მიზეზებით დაკარგა კავშირი თამაშის სერვერთან ან დატოვა თამაში.
					</p>
					<p>ავტო-ქეშაუთის ფუნქციონალი აქტიურდება შესაბამისი ღილაკით.</p>
					<p>
						ავტო-ქეშაუთის სამიზნე კოეფიციენტს უთითებს მომხმარებელი.
						აღნიშნული ველის მნიშვნელობა შეიძლება იყოს ნებისმიერი რიცხვი
						1.01-დან 1 000 000-ის ჩათვლით.
					</p>
					<p>
						არააქტიური ავტო-ქეშაუთის შემთხვევაში ბილეთის ავტომატური
						ქეშაუთი მოხდება “ტირაჟზე მომხმარებლის მაქსიმალური მოგების
						ლიმიტის” წესის შესაბამისად.
					</p>
				</Trans>
			</div>

			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.auto_bet'}
					components={i18TagMap()}
				>
					<h5>ავტო-ბეთი</h5>

					<p>
						ავტო-ბეთი იძლევა საშუალებას მითითებული კონფიგურაციით
						ავტომატურად განთავსდეს ბილეთი ყოველი ტირაჟის დაწყებისას.
					</p>
					<p>
						ავტო-ბეთის ღილაკის ჩართვისას, ავტობეთი გააქტიურდება მხოლოდ
						“ფსონის განთავსება” ან “განათავსე ყველა” ღილაკზე დაჭერის
						შემდეგ. ამგვარად, მომხმარებელს აქვს საშუალება მოამზადოს
						ბილეთების სასურველი კონფიგურაცია და შემდეგ მოიყვანოს
						მოქმედებაში.
					</p>
					<p>ავტო-ბეთი გაითიშება შემდეგ შემთხვევაში:</p>

					<ul>
						<li>ბილეთზე “ქეშაუთი” ღილაკზე დაჭერისას</li>
						<li>“გააქეშაუთე ყველა” ღილაკზე დაჭერისას</li>
						<li>ბალანსის ამოწურვისას</li>
						<li>გვერდის განახლებისას</li>
					</ul>
				</Trans>
			</div>

			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.rtp'}
					components={i18TagMap()}
				>
					<h5>RTP</h5>

					<p>საშუალო RTP შეადგენს 95.5%</p>
					<p>
						განთავსებული ფსონის 1% აკუმულირდება ჯეკპოტში შემდეგი
						პროპორციით:
					</p>

					<ul>
						<li>KILOWATT: განთავსებული ფსონის 0.33%</li>
						<li>MEGAWATT: განთავსებული ფსონის 0.33%</li>
						<li>GIGAWATT: განთავსებული ფსონის 0.34%</li>
					</ul>
				</Trans>
			</div>

			<div
				className={st.guideBlock}
				ref={(ref) => {
					jackpotRef.current = ref
					dispatch(
						setRefBlock({
							id: 'guideJackpotBlock',
							value: ref,
						}),
					)
				}}
			>
				<Trans
					i18nKey={'translation:messages.guide.jackpot'}
					components={i18TagMap()}
				>
					<h5>ჯეკპოტი</h5>

					<p>ჯეკპოტის სხვადასხვა დონე თამაშდება შემდეგი წესით:</p>

					<ul>
						<li>KILOWATT: 10 000-დან 30 000 ვოლტამდე</li>
						<li>MEGAWATT: 30 000-დან 100 000 ვოლტამდე</li>
						<li>GIGAWATT: 100 000 ვოლტიდან ზემოთ</li>
					</ul>

					<p>
						ჯეკპოტებში გათამაშებული თანხა ნაწილდება ტირაჟზე განთავსებული
						ფსონების პროპორციულად.
					</p>
					<p>
						ჯეკპოტის გათამაშებაში მონაწილეობისთვის აუცილებელია ბილეთი
						აკმაყოფილებდეს შემდეგ პირობებს:
					</p>

					<ul>
						<li>მინიმალური ფსონი: 1 ლარი</li>
						<li>მინიმალური განაღდების კოეფიციენტი: 2.0 ვოლტი</li>
					</ul>

					<p>
						<u>ჯეკპოტის გათამაშებაში არ მონაწილეობს</u>:
					</p>

					<ul>
						<li>ბილეთები 1 ლარამდე ფსონით</li>
						<li>ბილეთები რომლებიც განაღდებულია 2.0 ვოლტამდე</li>
						<li>FREEVOLT ბილეთები</li>
					</ul>

					<p>
						<b>
							<u>ჯეკპოტის რეზერვი</u>
						</b>
					</p>
					<p>
						ჯეკპოტის გათამაშებაში მონაწილეობს მთლიანი აკუმულირებული თანხის
						70%. დანარჩენი 30% წარმოადგენს სარეზერვო რესურსს, რომლითაც
						გათამაშების შემდგომ ხდება ახალი ჯეკპოტის შევსება.
					</p>
				</Trans>
			</div>

			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.reconnect'}
					components={i18TagMap()}
				>
					<h5>კავშირის წყვეტა და თამაშის აღდგენის წესი</h5>

					<p>
						მომხმარებლის მიერ გვერდის განახლების შემთხვევაში თამაში
						აღდგება გვერდის ჩატვირთვის მომენტში არსებული სიტუაციის
						მიხედვით:
					</p>

					<ul>
						<li>
							განთავსებული ბილეთები აღდგება და შესაძლებელი იქნება
							დაქეშაუთება თუ უკვე არ არის განაღდებული ავტო-ქეშაუთის
							ლოგიკის მიერ.
						</li>
						<li>
							მომხმარებლის თამაშში არ ყოფნის მომენტში თუ ტირაჟი დასრულდა
							ისეთ ნიშნულზე, როცა მომხმარებლის ბილეთში მითითებული
							ავტო-ქეშაუთით არ ექვემდებარებოდა განაღდებას, ასეთ
							შემთხვევაში ბილეთი ჩაითვლება წაგებულად.
						</li>
					</ul>

					<p>
						თუ მომხმარებელმა არ, ან ვერ გააგრძელა თამაში, იმოქმედებს
						ავტომატური ქეშაუთის ლოგიკა:
					</p>

					<ul>
						<li>
							თუ კოეფიციენტი მიაღწევს მომხმარებლის მიერ ბილეთზე მითითებულ
							ავტო-ქეშაუთის ნიშნულს, ბილეთი განაღდდება მიუხედავად იმისა
							მომხმარებელი იმყოფება თამაშში თუ არა.
						</li>
						<li>
							იმ შემთხვევაში თუ მომხმარებელი არ იმყოფება თამაშში და
							ბილეთზე გათიშულია ავტომატური ქეშაუთის პარამეტრი, იმოქმედებს{' '}
							<a href="#maxWinRule">
								განაღდების წესი მაქსიმალური მოგებისას
							</a>
						</li>
					</ul>
				</Trans>
			</div>

			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.lose'}
					components={i18TagMap()}
				>
					<h5>ბილეთის წაგება</h5>
					<p>
						ბილეთი ითვლება წაგებულად, თუ მისი განაღდება არ მოხდა ტირაჟის
						დასრულებამდე
					</p>
				</Trans>
			</div>

			<div className={st.guideBlock}>
				<Trans
					i18nKey={'translation:messages.guide.check_edition'}
					components={i18TagMap()}
				>
					<h5>ტირაჟის გადამოწმება</h5>
					<p>
						ტირაჟის გადამოწმება საშუალებას გაძლევთ დარწმუნდეთ, რომ ტირაჟში
						„ჩაწვის“ წერტილი წინასწარ არის განსაზღვრული და არ არის
						დამოკიდებული მომხმარებლების ფსონზე ამ რაიმე სხვა ფაქტორზე.
					</p>
					<p>
						სერვერზე წინაწარ არის შედგენილი მომავალი ტირაჟების სია და
						თამაშის პროცესი მიყვება მას რიგითობით. მომავალი ტირაჟების
						შედეგები არ ითვლება მიმდინარე რეჟიმში, რაც გამორიცხავს იმ
						მოსაზრებას, რომ სერვერის მხრიდან ტირაჟის ჩაწვის წერტილის
						გამოთვლა ხდება მომხმარებლის ქცევის გათვალიწინებით.
					</p>
					<p>
						თითოეული ტირაჟის სანდოობის კოდი შეიცავს 3 ძირითად პარამეტრს,
						რომლითაც იქმნება ტირაჟის გასაღები. ეს პარამეტრებია:
					</p>
					<ul>
						<li>ტირაჟის ნომერი</li>
						<li>ჩაწვის წერტილი</li>
						<li>ტირაჟის უნიკალური სერიული ნომერი</li>
					</ul>
					<p>
						ტირაჟის გასაღები წარმოადგენს ამ სამი პარამეტრის და სიტყვა{' '}
						<code>VOLT</code>-ის ტექსტურ გაერთიანებას.
					</p>
					<p>
						მაგალითად, თუ ტირაჟის ნომერი არის <code>123456</code>, ხოლო
						ტირაჟის ჩაწვის წერტილი - <code>4.65</code> და ტირაჟის
						უნიკალური ნომერი
						<code>a1b2c3d4e5f6g7h8i9j0klmnop</code>, მაშინ ტირაჟის
						გასაღებს ექნება შემდეგი სახე:
					</p>
					<center>
						<code>123456_4.65_VOLT_a1b2c3d4e5f6g7h8i9j0klmnop</code>
					</center>
					<p>
						თამაშის გვერდზე, „ტირაჟის შემოწმების“ ჩანართში მოცემულია
						გასული, მიმდინარე და მომავალი ტირაჟების სია. გასულ ტირაჟებზე
						ჩანს, როგორც გასაღები ასევე „სანდოობის კოდი“. მომავალ და
						მიმდინარე ტირაჟზე ჩანს მხოლოდ სანდოობის კოდი.
					</p>
					<p>
						სანდოობის კოდი წარმოადგენს <code>SHA256</code> ალგორითმით
						დაშიფრულ ტირაჟის გასაღებს. აღნიშნული ალგორითმით დაშიფრული
						გასაღები უნიკალურია და შეუძლებელია 2 სხვადასხვა გასაღებს
						ქონდეს ერთი და იგივე შიფრი. შესაბამისად თუ მოთამაშისათვის
						წინასწარ არის ცნობილი გასაღების შიფრი, ტირაჟის შედეგის შეცვლა
						ისე, რომ შიფრი არ შეიცვალოს, შეუძლებელია.
					</p>
					<p>
						დავუშვათ, ტირაჟის შემოწმების გვერდზე ვხედავთ მომავლი ტირაჟების
						ჩამონათვალს, რომელთაგან ერთერთი ტირაჟის სანდოობის კოდი არის:
					</p>
					<center>
						<code>
							10cfa29a0b183c8ac74959b9aa0add2f1e9224c147ef400f7f57e8266e5bb114
						</code>
					</center>
					<p>
						ველოდებით აღნიშნული ტირაჟზე გასაღების გამოჩენას. აღნიშნული
						გასაღები
						<code>SHA256</code> ალგორითმის გადაშიფრვით მიიღებთ წინასწარ
						ცნობილ სანდოობის კოდს.
					</p>
					<p>
						იმისათვის, რათა გაგიმარტივდეთ აღნიშნული პროცესი, შეგიძლიათ
						გამოიყენოთ ონლაინ <code>SHA256</code>
						კალკულატორები. მაგალითად:
					</p>
					<ul>
						<li>
							<a
								href="https://xorbin.com/tools/sha256-hash-calculator"
								target="_blank"
								rel="noreferrer"
							>
								https://xorbin.com/tools/sha256-hash-calculator
							</a>
						</li>
						<li>
							<a
								href="https://md5calc.com/hash/sha256"
								target="_blank"
								rel="noreferrer"
							>
								https://md5calc.com/hash/sha256
							</a>
						</li>
					</ul>
				</Trans>
			</div>
		</div>
	)
})

Guide.propTypes = {
	type: PropTypes.string,
}

export default Guide
