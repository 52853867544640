import { useDispatch } from 'react-redux'
import { setNavOpen } from '../store/actions/mobileAction'
import { useLocation, useNavigate } from 'react-router-dom'

const useNav = () => {
	const nav = useNavigate()
	const location = useLocation()
	const dispatch = useDispatch()

	// remove trailing slash
	location.pathname = location.pathname.replace(/\/+$/, '')

	const navigate = (url) => {
		nav(url)
		dispatch(setNavOpen(false))
	}
	return { navigate, location }
}
export default useNav
