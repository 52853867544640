import React, { useRef } from 'react'
import Modal from 'react-bootstrap/Modal'
import Draggable from 'react-draggable'
import ModalDialog from 'react-bootstrap/ModalDialog'
import cx from 'classnames'
import st from './Modal.module.scss'

const DraggableModalDialog = (props) => {
	const modalRef = useRef()
	return (
		<Draggable handle=".modal-header" nodeRef={modalRef}>
			<ModalDialog ref={modalRef} {...props} />
		</Draggable>
	)
}

const ModalApp = (props) => {
	return (
		<Modal
			dialogAs={props.draggable ? DraggableModalDialog : ModalDialog}
			animation={false}
			{...props}
			className={cx(st.modal, st.modalDraggable, props.className)}
		>
			{props.children}
		</Modal>
	)
}

export default ModalApp
