/* eslint-disable */
import storage from 'local-storage'
import {
	basicTypes,
	ErrorCodes,
	gameStates,
	messages,
	PMEndpoints,
	ticketStates,
	ToastID,
} from '../store/types'
import { toast } from 'react-toastify'
import { setGameState } from '../store/actions/apiActions'
import log from 'loglevel'
import { setJackpotAnimation } from '../store/actions/baseActions'
import * as types from '../store/types'

export const getUid = () => {
	return `${Date.now()}${Math.random().toFixed(3).replace('.', '')}`
}

export const toMoney = (money) => {
	return typeof money === 'string'
		? parseFloat(money).toFixed(2)
		: typeof money === 'number'
		? money.toFixed(2)
		: '0.00'
}

export const convertCoin = (money) => {
	return typeof money === 'string'
		? convertCoin(parseFloat(money))
		: typeof money === 'number'
		? (Math.floor(money) / 100).toFixed(2)
		: 0
}

export const moneyInputClean = (val) => {
	if (val === '' || val === null || val === undefined) {
		return ''
	}
	if (val === '.') return '0.'
	if (val === ',') return '0.'

	const regex =
		/(?<suffix>^[^\d]*)?(?<number>\d+((\.|,)?(\d{1,2})?)?)(?<prefix>.+)?/
	const result = regex.exec(val)
	if (result) {
		const {
			groups: { number },
		} = result
		return number.replace(',', '.')
	} else {
		return ''
	}
}

export const fancyInputs = (val, type, data, limit) => {
	val = parseFloat(Number(val).toFixed(2))
	val = isNaN(val) ? 1 : val < 0 ? Math.abs(val) : val

	if (type && data) {
		if (type === 'bet') {
			val = Math.max(val, data.minBet)
			if (limit) {
				val = Math.min(val, limit)
			}
		}
		if (type === 'cashout') {
			val = Math.max(val, data.minCashout)
			if (limit) {
				val = Math.min(val, limit)
			}
		}

		return val.toFixed(2)
	} else {
		return val.toFixed(2)
	}
}

export const getPossibleWin = (tickets) => {
	const cashoutOnTickets = tickets.filter((tkt) => tkt.autoCashout).length
	if (cashoutOnTickets < tickets.length) {
		return '--'
	}
	return toMoney(
		tickets.reduce((curr, next) => {
			return curr + Number(next.bet) * Number(next.cashout)
		}, 0),
	)
}

export const convertToKa = (str) => {
	let index,
		chr,
		text = [],
		symbols = 'abgdevzTiklmnopJrstufqRySCcZwWxjh'

	for (let i = 0; i < str.length; i++) {
		chr = str.substr(i, 1)
		if ((index = symbols.indexOf(chr)) >= 0) {
			text.push(String.fromCharCode(index + 4304))
		} else {
			text.push(chr)
		}
	}
	return text.join('')
}

export const getCookie = (key) => {
	return new RegExp(key + '=(.*?); ', 'gm').exec(document.cookie + '; ')?.[1]
}

export const parseJwt = (token) => {
	let base64Url = token.split('.')[1]
	let base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/')
	let jsonPayload = decodeURIComponent(
		atob(base64)
			.split('')
			.map(function (c) {
				return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2)
			})
			.join(''),
	)

	return JSON.parse(jsonPayload)
}

const getSearchItem = (array, index, searchKey) => {
	return searchKey ? array[index][searchKey] : array[index]
}

export const binarySearchIndex = (array, x, min, max, order = 1, key) => {
	// if empty array => insert at 0
	if (!array.length) return 0

	// get mid-index of array
	let mid = Math.floor(min + (max - min) / 2)
	let ord = order === 1 ? order : -1

	if (max === -1) return 0
	if (ord === 1) {
		if (getSearchItem(array, max, key) < x) return max + 1
		if (getSearchItem(array, min, key) > x) return min
		if (getSearchItem(array, mid, key) === x) return mid
		if (getSearchItem(array, mid + 1, key) === x) return mid + 1
		if (
			getSearchItem(array, mid, key) < x &&
			getSearchItem(array, mid + 1, key) > x
		)
			return mid + 1

		// deep into another part
		if (getSearchItem(array, mid, key) > x) max = mid
		if (getSearchItem(array, mid, key) < x) min = mid
	} else {
		if (getSearchItem(array, max, key) > x) return max + 1
		if (getSearchItem(array, min, key) < x) return min
		if (getSearchItem(array, mid, key) === x) return mid
		if (getSearchItem(array, mid + 1, key) === x) return mid + 1
		if (
			getSearchItem(array, mid, key) > x &&
			getSearchItem(array, mid + 1, key) < x
		)
			return mid + 1

		// deep into another part
		if (getSearchItem(array, mid, key) < x) max = mid
		if (getSearchItem(array, mid, key) > x) min = mid
	}

	return binarySearchIndex(array, x, min, max, order, key)
}

export const bettorCompare = (item1, item2) => {
	if (item1.isMine && !item2.isMine) {
		return 1
	}
	if (!item1.isMine && item2.isMine) {
		return -1
	}
	if (item1.isMine === item2.isMine) {
		if (!item1.profit && item2.profit) {
			return 1
		}
		if (item1.profit && !item2.profit) {
			return -1
		}
		if (!!item1.profit === !!item2.profit) {
			return -(item2.bet - item1.bet)
		}
	}
}

export const bettorIndex = (array, item) => {
	let left = 0
	let right = array.length - 1

	if (array.length === 0) {
		return 0
	}

	let middle = 0

	while (left <= right) {
		middle = Math.floor((right + left) / 2)

		if (bettorCompare(array[middle], item) > 0) {
			left = middle + 1
		} else if (bettorCompare(array[middle], item) < 0) {
			right = middle - 1
		} else {
			break
		}
	}

	if (left > right && right === array.length - 1) return array.length
	if (right < left) return left
	return middle
}

export const saveLocalStorage = (st) => {
	storage.set(basicTypes.STORAGE_KEY, st)
}

export const getLocalStorage = (key) => {
	if (key) {
		return storage.get(basicTypes.STORAGE_KEY)?.[key]
	}
	return storage.get(basicTypes.STORAGE_KEY)
}

export const setLocalStorage = (key, val) => {
	let st = getLocalStorage()
	st = st ?? {}
	st[key] = val
	saveLocalStorage(st)
}

export const callAuthWindow = () => {
	callPostMessage(PMEndpoints.AUTH)
}

export const checkTicketLimits = (state, data) => {
	// calc balances
	const realBal = parseFloat(convertCoin(state.userdata.balance.real))
	const virtualBal = parseFloat(convertCoin(state.userdata.balance.virtual))

	const [currentTicket] = state.tickets.filter((tkt) => tkt.id === data.id)
	const currentBet = parseFloat(currentTicket?.bet || 0)

	// calc bets
	const submittedTickets = state.tickets.filter(
		(tkt) => tkt.state === ticketStates.TICKET_SUBMITTED,
	)
	const totalBet = submittedTickets.reduce(
		(prev, curr) => prev + parseFloat(curr.bet),
		currentBet,
	)

	const totalStd = submittedTickets
		.filter((tkt) => tkt.std)
		.reduce(
			(prev, curr) => prev + parseFloat(curr.bet),
			currentTicket.std ? currentBet : 0,
		)

	const totalVirtual = submittedTickets
		.filter((tkt) => !tkt.virtual)
		.reduce(
			(prev, curr) => prev + parseFloat(curr.bet),
			currentTicket.std ? 0 : currentBet,
		)

	// check current ticket bet limit
	if (currentBet > state.limits.maxTotalBet) {
		displayError(ToastID.MAX_TOTAL_BET_SINGLE, state.limits.maxTotalBet)
		return false
	} else if (totalBet > state.limits.maxTotalBet) {
		displayError(ToastID.MAX_TOTAL_BET, state.limits.maxTotalBet)
		return false
	}

	// check for balance
	if (data.std) {
		if (totalStd > realBal) {
			displayError(ToastID.BALANCE_NOT_ENOUGH)
			return false
		}
	} else {
		if (totalVirtual > virtualBal) {
			displayError(ToastID.BALANCE_NOT_ENOUGH_VIRTUAL)
			return false
		}
	}

	// pass check
	return true
}

export const callPostMessage = (endpoint) => {
	if (Object.values(PMEndpoints).indexOf(endpoint) > -1) {
		try {
			window.parent.postMessage(endpoint, '*')
		} catch (e) {
			log.error(`Error: can't send message to parent window`, e)
		}
	} else {
		log.error(`Error: can't call parent window. Invalid message endpoint`)
	}
}

export const thousandSeparator = (x) => {
	return x.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ' ')
}

export const counterDigitsFormatter = (num) => {
	// return `${'0'.repeat(8)}${(Math.floor(num * 100) / 100).toFixed(2)}`.substr(-9)
	return `${thousandSeparator((Math.floor(num * 100) / 100).toFixed(2))}`
}

export const displayError = (type, data) => {
	// NOTE: log errors and warnings when trace is on
	log.trace('Trace: ', data, 'Source: ', data?.source)

	// set game loop on network hold
	if (type === ToastID.NETWORK_OFFLINE) {
		loopConfig.netProblem = true
	}

	if (type === ToastID.BALANCE_NOT_ENOUGH) {
		// call cashier window
		callPostMessage(PMEndpoints.CASHIER)
	}

	if (type === ToastID.SERVER_ERROR) {
		try {
			const message = JSON.parse(data?.source?.message)
			const code = message?.['exceptionTypes']
			const { key, limit } = message

			switch (code) {
				case ErrorCodes.BalanceNotEnough:
					// show error toast
					toast.error(messages[ToastID.BALANCE_NOT_ENOUGH], {
						toastId: ToastID.BALANCE_NOT_ENOUGH,
					})

					// call cashier window
					callPostMessage(PMEndpoints.CASHIER)
					break
				case ErrorCodes.BetValidationException:
					switch (key) {
						case 'maxTotalBet':
							toast.error(messages[ToastID.MAX_TOTAL_BET](limit / 100), {
								toastId: ToastID.MAX_TOTAL_BET,
							})
							break
						case 'maxTicketCount':
							toast.error(messages[ToastID.MAX_TICKETS](limit), {
								toastId: ToastID.MAX_TICKETS,
							})
							break
						case 'minBetPerTicket':
							toast.error(
								messages[ToastID.MIN_BET_PER_TICKET](limit / 100),
								{
									toastId: ToastID.MIN_BET_PER_TICKET,
								},
							)
							break
						case 'duplicateClientId':
							// skip this error
							break
						default:
							toast.error(messages[ToastID.GENERAL_ERROR], {
								autoClose: false,
								toastId: ToastID.GENERAL_ERROR,
							})
							break
					}
					break
				default:
					toast.error(messages[ToastID.GENERAL_ERROR], {
						autoClose: false,
						toastId: ToastID.GENERAL_ERROR,
					})
					break
			}
		} catch (err) {
			// error message is not JSON format
			const isAccessDenied = data?.source?.message === 'Access Denied'

			// show not authorized warning or general error
			if (isAccessDenied) {
				toast.warning(messages[ToastID.AUTH_REQUIRED], {
					autoClose: false,
					toastId: ToastID.AUTH_REQUIRED,
				})
			} else {
				loopConfig.error = true
				toast.error(messages[ToastID.GENERAL_ERROR], {
					autoClose: false,
					toastId: ToastID.GENERAL_ERROR,
				})
			}
		}
	} else {
		if (messages[type]) {
			if (type === ToastID.NETWORK_OFFLINE) {
				loopConfig.netProblem = true
				toast.warning(messages[type], {
					toastId: ToastID.NETWORK_OFFLINE,
					icon: () => <i className={'icon-wifi wifi-icon'} />,
				})
			} else if (typeof messages[type] === 'function') {
				toast.error(messages[type](data), {
					toastId: type,
				})
			} else {
				toast.error(messages[type], {
					toastId: type,
				})
			}
		} else {
			loopConfig.error = true
			toast.error(messages[ToastID.GENERAL_ERROR], {
				autoClose: false,
				toastId: ToastID.GENERAL_ERROR,
			})
		}
	}
}

export const i18TagMap = () => ({
	h5: <h5 />,
	p: <p />,
	ul: <ul />,
	li: <li />,
	b: <b />,
	span: <span />,
	u: <u />,
	code: <code />,
	center: <center />,
	a: <a />,
})

export const loopConfig = {
	state: null,
	time: 0,
	lastTick: 0,
	error: false,
	netProblem: false,
	balanceRequested: false,
}
export const jackpotBreakpoints = [10000, 30000, 100000]
// export const jackpotBreakpoints = [2, 5, 10]
export const sound = {
	enabled: false,
	instance: null,
	playList: [],

	stop: () => {
		sound.instance.volume = 0
		sound.instance.muted = true
	},

	enable: () => {
		log.info('Enabling sound...')
		sound.enabled = true
		sound.instance.volume = 0
		sound.instance.muted = true
		sound.instance.loop = true
		sound.instance.play()

		document.removeEventListener('click', sound.enable, true)
	},

	play: (playId) => {
		if (
			sound.enabled &&
			sound.instance &&
			(typeof playId === 'undefined' || !sound.playList.includes(playId))
		) {
			log.info('play jackpot sound')
			sound.instance.currentTime = 0
			sound.instance.volume = 0.7
			sound.instance.muted = false
			sound.instance.play()
			navigator.vibrate([
				100, 30, 100, 30, 100, 30, 200, 30, 200, 30, 200, 30, 100, 30, 100,
				30, 100,
			])
			sound.playList.push(playId)
		}
	},

	reset: () => {
		sound.instance.volume = 0
		sound.instance.muted = true
		sound.playList = []
	},
}

export const registerJackpotSound = (audioFile) => {
	// register jackpot sound
	sound.instance = new Audio(audioFile)

	// enable sound
	log.info('Sound enabled: ', sound.enabled)
	if (!sound.enabled) {
		// attach new listener
		document.addEventListener('click', sound.enable, true)
	}
}

let start, frameStart
export const gameLoop = (getState, dispatch, timestamp) => {
	if (loopConfig.error) {
		dispatch(
			setGameState({
				state: gameStates.ERROR,
			}),
		)
		return false
	}

	if (loopConfig.netProblem) {
		dispatch(
			setGameState({
				state: gameStates.NETWORK,
			}),
		)
	}

	start = start || timestamp
	frameStart = frameStart || timestamp

	let crash = Number.MAX_SAFE_INTEGER
	let delta = timestamp - frameStart


	if (delta > 150) {

		// if last tick of ST_GO_GO was more than 500ms ago -> stop graph
		if (loopConfig.state === gameStates.ST_GO_GO && (Date.now() - loopConfig.lastTick) > 2000) {
			// console.log('stop graph: last tick was >500ms ago', Date.now() - loopConfig.lastTick)
			if (loopConfig.netProblem) {
				displayError(ToastID.SERVER_ERROR)
				return false
			} else {
				loopConfig.netProblem = true
			}
		}

		frameStart = timestamp

		if (loopConfig.state === gameStates.ST_GO_GO && !loopConfig.netProblem) {
			let stateTimeElapsed = Date.now() - loopConfig.time
			let progress =
				Math.floor(
					100 * Math.pow(Math.E, 0.00006 * (1.5 * stateTimeElapsed)),
				) / 100
			let coef = Math.min(Math.floor(progress * 100) / 100, crash)

			if (coef >= jackpotBreakpoints[0] && coef < jackpotBreakpoints[1]) {
				sound.play(types.jackpotID.KILOWATT)
				dispatch(setJackpotAnimation(types.jackpotID.KILOWATT))
			} else if (
				coef >= jackpotBreakpoints[1] &&
				coef < jackpotBreakpoints[2]
			) {
				sound.play(types.jackpotID.MEGAWATT)
				dispatch(setJackpotAnimation(types.jackpotID.MEGAWATT))
			} else if (coef >= jackpotBreakpoints[2]) {
				sound.play(types.jackpotID.GIGAWATT)
				dispatch(setJackpotAnimation(types.jackpotID.GIGAWATT))
			}

			dispatch(
				setGameState({
					coef,
					state: gameStates.ST_GO_GO,
					elapsed: stateTimeElapsed,
				}),
			)
		}
	}
	requestAnimationFrame(gameLoop.bind(null, getState, dispatch))
}

export const stepChange = (
	val,
	type = 'inc',
	min = 0.1,
	max = 1000,
	valType = 'bet',
) => {
	let step = 0.1
	if (typeof val !== 'number') {
		val = parseFloat(val)
	}

	if (type === 'dec') {
		if (val > 1 && val <= 10) {
			step = 1
		}
		if (val > 10 && val <= 100) {
			step = 5
		}
		if (val > 100 && val <= 300) {
			step = 10
		}
		if (val > 300) {
			step = 50
		}
	} else {
		if (val >= 1 && val < 10) {
			step = 1
		}
		if (val >= 10 && val < 100) {
			step = 5
		}
		if (val >= 100 && val < 300) {
			step = 10
		}
		if (val >= 300) {
			step = 50
		}
	}

	// if cashout
	if (valType === 'cashout' && type === 'inc') {
		if (val >= 1.01 && val < 1.2) {
			step = 0.01
		}
		if (val >= 1.2 && val < 2) {
			step = 0.1
		}
		if (val >= 1000 && val < 5000) {
			step = 500
		}
		if (val >= 5000) {
			step = 1000
		}
	} else if (valType === 'cashout' && type === 'dec') {
		if (val >= 1.01 && val <= 1.2) {
			step = 0.01
		}
		if (val > 1.2 && val <= 2) {
			step = 0.1
		}
		if (val > 1000 && val <= 5000) {
			step = 500
		}
		if (val > 5000) {
			step = 1000
		}
	}

	if (type === 'dec') {
		step *= -1
	}

	val = val + step
	val = Math.max(val, min)
	val = Math.min(val, max)

	return toMoney(val)
}
