import React, { memo, useCallback, useEffect, useRef, useState } from 'react'
import Graph from '../../../Graph/Graph'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Ticket from '../../../Ticket/Ticket'
import {
	addTicket,
	crashTickets,
	createDefaultTicket,
	placeBet,
} from '../../../../store/actions/ticketActions'
import { gameStates } from '../../../../store/types'
import cx from 'classnames'
import st from 'scss/Mobile.module.scss'
import { useTranslation } from 'react-i18next'
import History from '../History/History'

const Main = memo(({ innerRef }) => {
	const dispatch = useDispatch()
	const tickets = useSelector((state) => state?.tickets, shallowEqual)
	const gameState = useSelector((state) => state?.gameState.state)
	const chartRef = useRef(null)
	const [isSticky, setIsSticky] = useState(false)
	const [isMini, setIsMini] = useState(false)
	const handleScroll = useCallback((e) => {
		setIsMini(e.target.scrollTop > 130)
	}, [])
	const virtualBalance = useSelector(
		(state) => state?.userdata.balance.virtual,
	)
	const auth = useSelector((state) => state?.userdata.auth)
	const { t } = useTranslation()

	useEffect(() => {
		if (!tickets.length) {
			dispatch(createDefaultTicket())
		}
	}, [dispatch, tickets, tickets.length])

	// submit bets on state change
	useEffect(() => {
		switch (gameState) {
			case gameStates.ST_START_BET:
				dispatch(placeBet())
				break
			case gameStates.ST_CRASH:
				dispatch(crashTickets())
				break
			default:
				break
		}
	}, [dispatch, gameState, tickets])

	// observe sticky on mount
	useEffect(() => {
		let chartRefValue = null
		let innerRefValue = null

		const observer = new IntersectionObserver(
			([e]) => {
				setIsSticky(e.intersectionRatio < 1)
			},
			{
				threshold: [1],
				rootMargin: '-1px 0px 0px 0px',
			},
		)

		if (chartRef.current) {
			observer.observe(chartRef.current)
			chartRefValue = chartRef.current
		}

		if (innerRef.current) {
			// scroll listener
			innerRef.current.addEventListener('scroll', handleScroll)
			innerRefValue = innerRef.current
		}

		// unmount
		return () => {
			if (chartRefValue) {
				observer.unobserve(chartRefValue)
			}
			if (innerRefValue) {
				innerRefValue.removeEventListener('scroll', handleScroll)
			}
		}
	}, [handleScroll, innerRef])

	return (
		<>
			<div className={st.page} data-val="main">
				<div
					className={cx(st.chartOuter, {
						[st.sticky]: isSticky,
						[st.mini]: isMini,
					})}
					ref={chartRef}
				>
					<div className={st.chartInner}>
						<div className={st.chartPanel}>
							<Graph type={'mob'} />
						</div>
					</div>
				</div>
				<div className={st.ticketsWrapper}>
					{tickets.map((ticketData, index) => (
						<Ticket data={ticketData} key={index} type={'mob'} />
					))}
				</div>
				<div className={st.betFooter}>
					<div className={st.innerNotch} />
					<div className={st.buttonsWrapper}>
						<div
							className={cx(st.addTicketBtn, st.regularTicketBtn)}
							onClick={() => dispatch(addTicket())}
						>
							<i className={'icon-add'} />
							{t('translation:common.regular_ticket')}
						</div>
						{auth && virtualBalance > 0.01 && (
							<div
								className={cx(st.addTicketBtn, st.freespinTicketBtn)}
								onClick={() => dispatch(addTicket({ virtual: true }))}
							>
								<i className={'icon-add'} />
								{t('translation:common.freespin_ticket')}
							</div>
						)}
					</div>
				</div>
				<History />
			</div>
		</>
	)
})
export default Main
