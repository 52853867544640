import { Fragment, memo } from 'react'
import st from './PlayersList.module.scss'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { convertCoin, toMoney } from '../../helpers/helpers'
import { useTranslation } from 'react-i18next'

const PlayersList = memo(({ data }) => {
	const { t } = useTranslation()
	return (
		<Fragment>
			<div className={st.tableHeadOuter}>
				<table className={cx(st.playersTable, st.tableHead)} border={0}>
					<thead>
						<tr>
							<td className={st.colPlayer}>
								{t('translation:common.user')}
							</td>
							<td className={st.colCashout}>
								{t('translation:common.cashout')}
							</td>
							<td className={st.colBet}>
								{t('translation:common.bet')}
							</td>
							<td className={st.colWin}>
								{t('translation:common.win')}
							</td>
						</tr>
					</thead>
				</table>
			</div>
			<div className={st.tableBodyOuter}>
				<table className={cx(st.playersTable, st.tableBody)} border={0}>
					<tbody>
						{data.map((row, ix) => (
							<tr
								key={ix}
								className={cx({
									[st.active]: row.active,
									[st.self]: row.isMine,
								})}
							>
								<td className={st.colPlayer}>{row.usrName}</td>
								<td className={st.colCashout}>
									{row.coef ? (
										<span>
											<i className={'icon-volt'} />
											{toMoney(row.coef)}v
										</span>
									) : (
										'-'
									)}
								</td>
								<td className={st.colBet}>
									{row.bet ? (
										<Fragment>
											<i className={'icon-currency icon-gel'} />{' '}
											{convertCoin(row.bet)}
										</Fragment>
									) : (
										'-'
									)}
								</td>
								<td className={st.colWin}>
									{row.profit ? (
										<span>
											<i className={'icon-currency icon-gel'} />
											{convertCoin(row.profit)}
										</span>
									) : (
										'-'
									)}
								</td>
							</tr>
						))}
					</tbody>
				</table>
			</div>
		</Fragment>
	)
})

PlayersList.displayName = 'PlayerList'
PlayersList.propTypes = {
	data: PropTypes.array.isRequired,
}

export default PlayersList
