import st from './Jackpot.module.scss'
import PropTypes from 'prop-types'
import { useCountUp } from 'react-countup'
import { memo, useEffect, useRef } from 'react'
import cx from 'classnames'
import { counterDigitsFormatter } from '../../helpers/helpers'
import { useDispatch, useSelector } from 'react-redux'
import { setActiveTab } from '../../store/actions/baseActions'
import useNav from '../../hooks/useNav'

const Jackpot = memo(({ id, title, value, digits, type }) => {
	const guideRefBlock = useSelector((state) => state?.refs?.guideJackpotBlock)
	const animating = useSelector((st) => st?.jackpot.animating)
	const dispatch = useDispatch()
	const counterRef = useRef(null)
	const { navigate } = useNav()
	const { update } = useCountUp({
		ref: counterRef,
		start: 0,
		end: value,
		duration: 5,
		formattingFn: counterDigitsFormatter,
		decimals: 5,
		decimal: '.',
	})

	useEffect(() => {
		update(value)
	}, [update, value])

	return (
		<div
			className={cx(st.jackpot, {
				[st.mobile]: type === 'mob',
				[st.animate]: id === animating,
			})}
			onClick={() => {
				if (type === 'mob') {
					navigate('guide#jackpot')
				} else {
					dispatch(setActiveTab('help'))
				}
				if (guideRefBlock) {
					setTimeout(() => {
						guideRefBlock.scrollIntoView({
							behavior: 'smooth',
						})
					}, 10)
				}
			}}
		>
			<div className={st.jackpotRow}>
				<span ref={counterRef} />
				<i className={'icon-gel'} />
			</div>
			<div className={st.title}>{title}</div>
		</div>
	)
})
Jackpot.propTypes = {
	id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
	title: PropTypes.string.isRequired,
	value: PropTypes.number.isRequired,
	digits: PropTypes.number.isRequired,
	type: PropTypes.oneOf(['mob', 'web']),
}
Jackpot.defaultProps = {
	digits: 8,
	type: 'web',
}
export default Jackpot
