import React, { memo } from 'react'
import Jackpot from '../../Jackpot/Jackpot'
import { shallowEqual, useSelector } from 'react-redux'
import st from 'scss/Mobile.module.scss'

const Jackpots = memo(() => {
	const jackpot = useSelector((state) => state?.jackpot, shallowEqual)

	return (
		<div className={st.jackpots}>
			{jackpot?.data.map((jp) => (
				<Jackpot
					key={jp['ID']}
					id={jp['ID']}
					title={jackpot.types[jp['ID']]}
					value={jp['AMOUNT'] / 100}
					digits={8}
					type={'mob'}
				/>
			))}
		</div>
	)
})

export default Jackpots
