import { createAction } from '@reduxjs/toolkit'
import actionTypes from '../types/actionTypes'
import { ticketStates, ToastID } from '../types'
import service from '../../api/services'
import { displayError } from '../../helpers/helpers'

export const createDefaultTicket = createAction(
	actionTypes.CREATE_DEFAULT_TICKET,
)
export const setTicketData = createAction(actionTypes.SET_TICKET_DATA)
export const setTicketBet = createAction(actionTypes.SET_TICKET_BET)
export const setTicketCashout = createAction(actionTypes.SET_TICKET_CASHOUT)
export const cleanTicketInputs = createAction(actionTypes.CLEAN_TICKET_INPUTS)
export const submitBetShortcut = createAction(actionTypes.SUBMIT_BET_SHORTCUT)
export const submitCashoutShortcut = createAction(
	actionTypes.SUBMIT_CASHOUT_SHORTCUT,
)
export const setAutoBet = createAction(actionTypes.SET_AUTO_BET)
export const setAutoCashout = createAction(actionTypes.SET_AUTO_CASHOUT)
export const addTicket = createAction(actionTypes.ADD_TICKET)
export const removeTicket = createAction(actionTypes.REMOVE_TICKET)
export const submitTicket = createAction(actionTypes.SUBMIT_TICKET)
export const cancelTicket = createAction(actionTypes.CANCEL_TICKET)
export const userBetPlaced = createAction(actionTypes.USER_BET_PLACED)
export const placeBet = (single) => (dispatch, getState) => {
	const state = getState()
	let betData
	if (single) {
		betData = [
			{
				cId: single.id,
				bet: Math.floor(single.bet * 100),
				cashOutAt: single.autoCashout ? parseFloat(single.cashout) : -1,
				currencyType: single.std ? 'REAL' : 'VIRTUAL',
			},
		]
	} else {
		betData = state.tickets
			.filter((tkt) => tkt.state === ticketStates.TICKET_SUBMITTED)
			.map((tkt) => ({
				cId: tkt.id,
				bet: Math.floor(tkt.bet * 100),
				cashOutAt: tkt.autoCashout ? parseFloat(tkt.cashout) : -1,
				currencyType: tkt.std ? 'REAL' : 'VIRTUAL',
			}))
	}

	if (betData.length) {
		// check limits
		const totalBet = betData
			.filter((tkt) => tkt.currencyType === 'REAL')
			.reduce((curr, next) => curr + next.bet / 100, 0)

		if (totalBet > state.limits.maxTotalBet) {
			displayError(ToastID.MAX_TOTAL_BET, state.limits.maxTotalBet)
		} else {
			service.placeBet(state.gameState.editionId, betData, (data) => {
				dispatch(userBetPlaced(data))
			})
		}
	}
}
export const crashTickets = createAction(actionTypes.CRASH_TICKETS)
export const submitTicketsAll = createAction(actionTypes.SUBMIT_TICKETS_ALL)
export const cancelTicketsAll = createAction(actionTypes.CANCEL_TICKETS_ALL)
export const cashoutAll = createAction(actionTypes.CASHOUT_ALL)
export const cashout = createAction(actionTypes.CASHOUT)
export const resetBettors = createAction(actionTypes.RESET_BETTORS)
export const reviewTicketsOnAuth = createAction(
	actionTypes.REVIEW_TICKETS_ON_AUTH,
)
export const transformFreespinTickets = createAction(
	actionTypes.TRANSFORM_FREESPIN_TICKETS,
)
export const tktStepChange = createAction(actionTypes.TKT_STEP_CHANGE)
