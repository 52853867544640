import {Fragment, memo} from "react";
import {useSelector} from "react-redux";
import st from "./TicketButton.module.scss";
import {toMoney} from "../../helpers/helpers";
import {useTranslation} from "react-i18next";
import PropTypes from "prop-types";

const BetCashoutButton = memo(({bet, virtual}) => {
    const { t } = useTranslation()
    const gameCoef = useSelector((state) => state?.gameState.coef)

    return (
        <Fragment>
            <div className={st.btnLabel}>
                {t('translation:common.cashout')}
            </div>
            <div className={st.btnCashoutValue}>
                {virtual
                    ? toMoney(gameCoef * bet - bet)
                    : toMoney(gameCoef * bet)}
                <i className={'icon-currency icon-gel'} />
            </div>
        </Fragment>
    )
})

BetCashoutButton.displayName = 'BetCashoutButton'
BetCashoutButton.propTypes = {
    bet: PropTypes.number.isRequired,
    virtual: PropTypes.bool.isRequired,
}

export default BetCashoutButton
