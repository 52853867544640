import React, { memo, useEffect, useRef } from 'react'
import History from '../../../History/History'
import { useNavigate } from 'react-router-dom'
import st from 'scss/Mobile.module.scss'
import { useTranslation } from 'react-i18next'
import { useDispatch, useSelector } from 'react-redux'
import { setUIAction } from '../../../../store/actions/baseActions'

const HistoryScreen = memo((props) => {
	const navigate = useNavigate()
	const { t } = useTranslation()
	const titleRef = useRef(null)
	const dispatch = useDispatch()
	const historyActionFired = useSelector(
		(state) => state?.ui.historyActionFired,
	)

	useEffect(() => {
		if (historyActionFired && titleRef) {
			titleRef.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			})

			// remove history action fired status
			dispatch(
				setUIAction({
					id: 'historyActionFired',
					value: false,
				}),
			)
		}
	}, [dispatch, historyActionFired])

	return (
		<div className={st.historyWrapper}>
			<div className={st.title} ref={titleRef}>
				{t('translation:common.history')}
			</div>
			<History type={'mob'} navigate={navigate} />
		</div>
	)
})
export default HistoryScreen
