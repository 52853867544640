import PropTypes from 'prop-types'
import { memo } from 'react'

const Tab = memo((props) => {
	return <div>tab</div>
})

Tab.propTypes = {
	id: PropTypes.string.isRequired,
	title: PropTypes.string.isRequired,
	icon: PropTypes.string,
}

export default Tab
