import st from './History.module.scss'
import tb from 'scss/tables.common.module.scss'
import { shallowEqual, useSelector } from 'react-redux'
import { memo } from 'react'
import FairCheckRow from './FairCheckRow'
import cx from 'classnames'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

const FairCheck = memo(({ type }) => {
	const history = useSelector((state) => state?.history, shallowEqual)
	const currentEdition = useSelector((state) => state?.gameState.editionId)
	const selectedEditionId = useSelector((state) => state?.selectedEditionId)
	const { t } = useTranslation()

	return (
		<div className={cx(st.history, { [tb.mobile]: type === 'mob' })}>
			<div className={st.tableHeadOuter}>
				<table className={tb.table}>
					<colgroup>
						<col className={tb.edition} />
						<col className={tb.outcome} />
						<col className={tb.key} />
						<col className={tb.hash} />
					</colgroup>
					<thead>
						<tr>
							<th className={tb.edition}>{t('common.edition')}</th>
							<th className={tb.outcome}>{t('common.outcome')}</th>
							<th className={tb.key}>{t('common.key')}</th>
							<th className={tb.hash}>{t('common.hash')}</th>
						</tr>
					</thead>
				</table>
			</div>
			<div className={st.tableBodyOuter}>
				<table className={tb.table}>
					<colgroup>
						<col className={tb.edition} />
						<col className={tb.outcome} />
						<col className={tb.key} />
						<col className={tb.hash} />
					</colgroup>
					<tbody>
						{history.map((row) => (
							<FairCheckRow
								key={row.editionId}
								data={row}
								isCurrentEdition={row.editionId === currentEdition}
								selected={row.editionId === selectedEditionId}
							/>
						))}
					</tbody>
				</table>
			</div>
		</div>
	)
})

FairCheck.displayName = 'FairCheck'
FairCheck.propTypes = {
	type: PropTypes.oneOf(['mob', 'web']),
}
FairCheck.defaultProps = {
	type: 'web',
}

export default FairCheck
