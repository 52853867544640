import React, { memo, useCallback, useEffect, useRef } from 'react'
import SideNavDrawer from './SideNavDrawer'
import useNav from '../../../hooks/useNav'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import st from 'scss/Mobile.module.scss'
import { setNavOpen } from '../../../store/actions/mobileAction'
import cx from 'classnames'
import Balance from '../../Header/Balance'
import { callAuthWindow } from '../../../helpers/helpers'
import { useTranslation } from 'react-i18next'
import { appExit } from '../../../store/actions/apiActions'
import { version } from '../../../../package.json'
import { setUIAction } from '../../../store/actions/baseActions'

const SideNav = memo(({ contentRef, wrapperRef, userData }) => {
	const sideNavRef = useRef()
	const { location, navigate } = useNav()
	const { auth, pin, username } = userData
	const dispatch = useDispatch()
	const sidenavCallback = useCallback(
		(state) => {
			if (state === 'open') {
				dispatch(setNavOpen(true))
			} else if (state === 'close') {
				dispatch(setNavOpen(false))
			}
		},
		[dispatch],
	)
	const balance = useSelector((state) => state?.userdata.balance, shallowEqual)
	const { t } = useTranslation()

	useEffect(() => {
		if (contentRef && wrapperRef && sideNavRef) {
			new SideNavDrawer({
				wrapper: wrapperRef?.current,
				sidenav: sideNavRef?.current,
				content: contentRef?.current,
				openClass: st.open,
				closeClass: st.closed,
				openTransitionClass: st.openStarted,
				closeTransitionCLass: st.closeStarted,
				stateCallback: sidenavCallback,
				hitArea: 0.5,
			})
		}
	}, [contentRef, wrapperRef, sideNavRef, sidenavCallback])

	return (
		<div className={st.sideNav} ref={sideNavRef}>
			<div className={st.navCap}>
				{auth ? (
					<div className={st.profileWrapper}>
						<div className={st.userProfile}>
							<div className={st.avatar} />
							<div className={st.usernameRow}>
								<div className={st.username}>
									<span className="value">{username}</span>
								</div>
								<div className={st.pin}>
									<span className={st.title}>PIN:</span>
									<span className={st.value}>{pin}</span>
								</div>
							</div>
						</div>
						<div className={st.balance}>
							{balance.virtual > 0.1 && (
								<Balance
									title={'FREEVOLT'}
									value={balance?.virtual || 0}
									device={'mob'}
									type="virtual"
								/>
							)}
							<Balance
								title={t('translation:common.balance')}
								value={balance?.real || 0}
								device={'mob'}
							/>
						</div>
					</div>
				) : (
					<div className={st.btnAuth} onClick={() => callAuthWindow()}>
						{t('translation:common.authorization')}
					</div>
				)}
			</div>
			<ul className={st.mainNav}>
				<li data-page="main" onClick={() => dispatch(appExit())}>
					{t('translation:common.main')}
				</li>
				<li
					data-page="game"
					className={cx({ [st.active]: location.pathname === '/m' })}
					onClick={() => navigate('')}
				>
					{t('translation:common.game')}
				</li>
				<li
					data-page="history"
					className={cx({
						[st.active]: location.pathname === '/m/history',
					})}
					onClick={(e) => {
						dispatch(
							setUIAction({
								id: 'historyActionFired',
								value: true,
							}),
						)
						navigate('')
					}}
				>
					{t('translation:common.history')}
				</li>
				{auth && (
					<>
						{/*<li data-page="stat" className={cx({[st.active]: location.pathname === '/stat'})}  onClick={()=>navigate("/stat")}>სტატისტიკა</li>*/}
						<li
							data-page="check"
							className={cx({
								[st.active]: location.pathname === '/m/check',
							})}
							onClick={() => navigate('check')}
						>
							{t('translation:common.check_edition')}
						</li>
						<li data-page="cashier">{t('translation:common.cashier')}</li>
					</>
				)}
				<li className={st.divider} />
				{/*<a data-page="promo"*/}
				{/*   target="_blank"*/}
				{/*   className={cx(st.promoHighlight)}*/}
				{/*   href='//m.lider-bet.com/ka/promotion/?promo=playVolt2'*/}
				{/*   rel="noreferrer"*/}
				{/*>*/}
				{/*	PROMO*/}
				{/*</a>*/}
				<li
					data-page="rules"
					className={cx({ [st.active]: location.pathname === '/m/guide' })}
					onClick={() => navigate('guide')}
				>
					{t('translation:common.rules')}
				</li>
				{/*<li data-page="help" onClick={() => navigate('/guide')}>დახმარება</li>*/}
				{/*<li data-page="close" onClick={() => callPostMessage(PMEndpoints.EXIT)}>{ t('translation:common.close') }</li>*/}
			</ul>
			<div className={st.version}>v{version}</div>
		</div>
	)
})

export default SideNav
