import React, { forwardRef } from 'react'
import st from 'scss/Mobile.module.scss'
import { useDispatch, useSelector } from 'react-redux'
import { setNavOpen } from '../../../store/actions/mobileAction'

const Content = forwardRef(({ children }, ref) => {
	const dispatch = useDispatch()
	const isNavOpen = useSelector((state) => state?.mobile.isNavOpen)

	return (
		<div
			className={st.content}
			ref={ref}
			onClick={(e) => {
				if (isNavOpen) {
					dispatch(setNavOpen(false))
				}
			}}
		>
			{children}
		</div>
	)
})

export default Content
