import st from './Preloader.module.scss'
import Loader from '../Loader/Loader'
import { memo } from 'react'

const Preloader = memo(() => {
	return (
		<div className={st.preloader}>
			<Loader />
		</div>
	)
})

export default Preloader
