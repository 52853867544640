import st from './History.module.scss'
import tb from 'scss/tables.common.module.scss'
import HistoryRow from './HistoryRow'
import { shallowEqual, useSelector } from 'react-redux'
import React, { memo } from 'react'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { useTranslation } from 'react-i18next'

const History = memo(({ type, navigate }) => {
	const history = useSelector((state) => state?.history, shallowEqual)
	const currentEdition = useSelector((state) => state?.gameState.editionId)
	const { t } = useTranslation()

	return (
		<div className={cx(st.history, { [tb.mobile]: type === 'mob' })}>
			<div className={st.tableHeadOuter}>
				<table className={tb.table}>
					<colgroup>
						<col className={tb.edition} />
						<col className={tb.outcome} />
						<col className={tb.cashout} />
						<col className={tb.bet} />
						<col className={tb.win} />
						<col className={tb.check} />
					</colgroup>
					<thead>
						<tr>
							<th className={tb.edition}>
								{t('translation:common.edition')}
							</th>
							<th className={tb.outcome}>
								{t('translation:common.outcome')}
							</th>
							<th className={tb.cashout}>
								{t('translation:common.cashout')}
							</th>
							<th className={tb.bet}>{t('translation:common.bet')}</th>
							<th className={tb.win}>{t('translation:common.win')}</th>
							<th className={tb.check} />
						</tr>
					</thead>
				</table>
			</div>
			<div className={st.tableBodyOuter}>
				<table className={tb.table}>
					<colgroup>
						<col className={tb.edition} />
						<col className={tb.outcome} />
						<col className={tb.cashout} />
						<col className={tb.bet} />
						<col className={tb.win} />
						<col className={tb.check} />
					</colgroup>
					<tbody>
						{history
							.filter((row) => !row['isFuture'])
							.map((row) => (
								<HistoryRow
									key={row.editionId}
									data={row}
									isCurrentEdition={row.editionId === currentEdition}
									type={type}
									navigate={navigate}
								/>
							))}
					</tbody>
				</table>
			</div>
		</div>
	)
})

History.propTypes = {
	type: PropTypes.oneOf(['mob', 'web']),
	navigate: PropTypes.func,
}
History.defaultProps = {
	type: 'web',
}

History.displayName = 'History'
export default History
