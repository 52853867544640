import React, { memo, useEffect, useState } from 'react'
import cx from 'classnames'
import st from '../../scss/global.module.scss'
import { cancelTicketsAll } from '../../store/actions/ticketActions'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ticketStates } from '../../store/types'
import PropTypes from 'prop-types'

const CancelAllBtn = memo(({ mob }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [totalSubmitted, setTotalSubmitted] = useState(0)
	const tickets = useSelector((state) => state?.tickets, shallowEqual)

	useEffect(() => {
		setTotalSubmitted(
			tickets.filter((tkt) => tkt.state === ticketStates.TICKET_SUBMITTED)
				.length,
		)
	}, [tickets])

	return (
		<div
			className={cx(st.btn, st.cancelAll, {
				[st.active]: totalSubmitted > 0,
				[st.mobile]: mob,
			})}
			onClick={() => dispatch(cancelTicketsAll())}
		>
			{t('translation:common.cancel_all')}
		</div>
	)
})

CancelAllBtn.propTypes = {
	mob: PropTypes.bool,
}

CancelAllBtn.defaultProps = {
	mob: true,
}

export default CancelAllBtn
