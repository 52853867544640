import MessageService from './MessageService'
import {
	setDualSession,
	setJackpotAnimation,
	setJackpotDrop,
	setJackpots,
	setLimits,
	setUserData,
} from '../store/actions/baseActions'
import {
	setBettors,
	setGameState,
	setHistory,
} from '../store/actions/apiActions'
import {
	callPostMessage,
	displayError,
	loopConfig,
	sound,
} from '../helpers/helpers'
import { gameStates, PMEndpoints, ToastID } from '../store/types'
import log from 'loglevel'

let server

let connected = false

const service = {
	// connect server
	connect: (token, jwt, dispatch, uid) => {
		if (connected) {
			return false
		}
		server = new MessageService(uid)
		server.connect(token, jwt).then(() => {
			connected = true

			// get limits config
			server.requestResponse('volt.config', {}).then((data) => {
				if (data) {
					dispatch(
						setLimits({
							minBetPerTicket: parseFloat(data.minBetPerTicket / 100),
							maxTicketCount: parseInt(data.maxTicketCount, 10),
							maxTotalBet: parseFloat(data.maxTotalBet / 100),
						}),
					)
				}
			})

			// subscribe to balance
			server.requestStream(
				'volt.events.balance',
				{},
				(data) => {
					if (!loopConfig.balanceRequested && data.real < 10) {
						loopConfig.balanceRequested = true
						callPostMessage(PMEndpoints.CASHIER)
					}
					dispatch(
						setUserData({
							balance: {
								real: data.real,
								virtual: data.virtual,
							},
						}),
					)
				},
				(err) => displayError(ToastID.SERVER_ERROR, err),
			)

			// subscribe to game state stream
			server.requestStream(
				'volt.states.stream',
				{},
				(data) => {
					if (loopConfig.error) {
						return false
					}

					// on any network activity remove network problem flag
					loopConfig.netProblem = false

					// save las GO_GO time
					if (data.state === gameStates.ST_GO_GO) {
						loopConfig.lastTick = Date.now()
					}

					if (data.state !== loopConfig.state) {
						loopConfig.state = data.state
						if (data.coef === 1) {
							loopConfig.time = Date.now()
						} else if (data.elapsed) {
							loopConfig.time = Date.now() - data.elapsed
						} else {
							loopConfig.time = Date.now()
						}
					}

					if (data.state !== gameStates.ST_GO_GO) {
						// reset jackpot sound and effects
						if (data.state === gameStates.ST_CRASH) {
							sound.reset()
							dispatch(setJackpotAnimation(null))
						}

						dispatch(setGameState(data))
					}
				},
				(err) => displayError(ToastID.SERVER_ERROR, err),
			)

			// subscribe to history stream
			server.requestStream(
				'volt.history',
				{},
				(data) => {
					dispatch(setHistory(data))
				},
				(err) => displayError(ToastID.SERVER_ERROR, err),
			)

			// subscribe to bettors stream
			server.requestStream(
				'volt.events.tickets',
				{},
				(data) => {
					dispatch(setBettors(data))
				},
				(err) => displayError(ToastID.SERVER_ERROR, err),
			)

			// subscribe to jackpots stream
			server.requestStream(
				'volt.jackpot',
				{},
				(data) => {
					dispatch(setJackpots(data))
				},
				(err) => displayError(ToastID.SERVER_ERROR, err),
			)

			// subscribe to jackpot win
			server.requestStream(
				'volt.jackpot.win',
				{},
				(data) => {
					if (data['jackpotAmount'] && data['jackpotWin']) {
						dispatch(
							setJackpotDrop({
								visible: true,
								amount: data['jackpotAmount'],
								win: data['jackpotWin'],
								id: data['jackpotId'],
							}),
						)
					}
				},
				(err) => displayError(ToastID.SERVER_ERROR, err),
			)

			// request channel
			let subscriber = server.requestChannel('volt.channel', (token) => {
				switch (token) {
					case 'INVALID':
						try {
							window.location.reload()
						} catch (e) {}
						break
					default:
						if (token !== 'PONG' && token !== uid) {
							// close connection
							server.closeConnection()

							// show dual connection message
							dispatch(setDualSession(true))
						}
						break
				}
				subscriber.onNext(Date.now())
			})
			subscriber.onSubscribe({
				cancel: () => log.warn('Channel connection canceled'),
				request: () => {
					subscriber.onNext(Date.now())
				},
			})
		})
	},

	placeBet: (editionId, betData, callback) => {
		server.placeBet(
			{
				editionId,
				bets: betData,
			},
			callback,
		)
	},

	cashout: (editionId, betIds) => {
		server.cashout({
			editionId,
			betId: betIds,
		})
	},

	close: () => {
		server.closeConnection()
	},
}

export default service
