import { callPostMessage, getCookie, parseJwt } from '../../helpers/helpers'
import { setUserData } from './baseActions'
import service from '../../api/services'
import { createAction } from '@reduxjs/toolkit'
import actionTypes from '../types/actionTypes'
import { config } from '../../api/config'
import { uid } from 'uid/secure'
import { PMEndpoints } from '../types'
import log from 'loglevel'

/** @return {(function(*, *, import(axios).create): void)|*} */
export const userLogin = () => (dispatch, getState, api) => {
	// generate uuid
	const uuid = uid(32)

	// save uuid
	dispatch(setUserData({ uuid }))

	try {
		// get cookie
		const cookie = getCookie('liderbet')

		// connect
		api.get(config.endpoints.login, {
			headers: {
				'X-liderbet': cookie,
			},
			params: {
				uuid: uuid,
			},
		})
			.then((res) => {
				const token = Buffer.from(res.data, 'utf8')
				const jwt = parseJwt(res.data)

				dispatch(
					setUserData({
						auth: true,
						balance: 0,
						username: jwt['name'],
						pin: jwt['sub'],
						hash: jwt['hName'],
						token: token,
						jwt: jwt,
					}),
				)

				// connect to server
				// service.connect(token, jwt, dispatch, jwt['ses'])
				service.connect(token, jwt, dispatch, uuid)
			})
			.catch((err) => {
				dispatch(
					setUserData({
						auth: false,
						balance: 0,
						username: null,
						pin: null,
						hash: null,
						token: null,
						jwt: null,
					}),
				)

				// connect
				// service.connect(null, null, dispatch, err?.response?.data ?? uuid)
				service.connect(null, null, dispatch, uuid)
			})
	} catch (e) {
		// error
		log.error('ERROR', e)
		// TODO: auth failed whats next?
	}
}
export const appExit = () => (dispatch, getState, api) => {
	api.get(config.endpoints.exit, {
		withCredentials: true,
	})
		.then((res) => {
			callPostMessage(PMEndpoints.EXIT)
		})
		.catch((err) => {
			callPostMessage(PMEndpoints.EXIT)
		})
}
export const setGameState = createAction(actionTypes.SET_GAME_STATE)
export const setHistory = createAction(actionTypes.SET_HISTORY)
export const setBettors = createAction(actionTypes.SET_BETTORS)
