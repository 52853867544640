import { configureStore } from '@reduxjs/toolkit'
import initialStore from './initialStore'
import rootReducer from './reducers/'
import thunk from 'redux-thunk'
import api from '../api'

export default configureStore({
	reducer: rootReducer,
	preloadedState: initialStore,
	devTools: process.env.NODE_ENV !== 'production',
	middleware: [thunk.withExtraArgument(api)],
})
