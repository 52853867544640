import st from './panels.module.scss'
import PropTypes from 'prop-types'
import cx from 'classnames'
import { forwardRef } from 'react'

const Panel = forwardRef((props, ref) => {
	const { className, ...restProps } = props
	return (
		<div ref={ref} className={cx(st.panel, className)} {...restProps}>
			{props.children}
		</div>
	)
})

Panel.displayName = 'Panel'
Panel.propTypes = {
	className: PropTypes.oneOfType([PropTypes.object, PropTypes.string]),
	style: PropTypes.object,
	onClick: PropTypes.func,
}
export default Panel
