import actionTypes from '../types/actionTypes'
import { createAction } from '@reduxjs/toolkit'

// userData
export const setUserData = createAction(actionTypes.SET_USER_DATA)
export const setLimits = createAction(actionTypes.SET_LIMITS)

// uiData
export const setActiveTab = createAction(actionTypes.SET_ACTIVE_TAB)
export const checkEdition = createAction(actionTypes.CHECK_EDITION)
export const setGraphSize = createAction(actionTypes.SET_GRAPH_SIZE)
export const setDualSession = createAction(actionTypes.SET_DUAL_SESSION)
export const setRefBlock = createAction(actionTypes.SET_REF_BLOCK)
export const setUIAction = createAction(actionTypes.SET_UI_ACTION)

// chat
export const setChatData = createAction(actionTypes.SET_CHAT_DATA)
export const openChat = createAction(actionTypes.OPEN_CHAT)
export const closeChat = createAction(actionTypes.CLOSE_CHAT)
export const toggleInputLang = createAction(actionTypes.TOGGLE_INPUT_LANG)

// jackpots
export const setJackpots = createAction(actionTypes.SET_JACKPOT)
export const setJackpotDrop = createAction(actionTypes.SET_JACKPOT_DROP)
export const setJackpotAnimation = createAction(
	actionTypes.SET_JACKPOT_ANIMATION,
)

// modals
export const openModal = createAction(actionTypes.OPEN_MODAL)
export const closeModal = createAction(actionTypes.CLOSE_MODAL)
