import 'react-app-polyfill/stable'
import Preloader from './components/Preloader/Preloader'
import React, { Suspense } from 'react'
import ReactDOM from 'react-dom'
import { Provider } from 'react-redux'
import store from 'store'
import log from 'loglevel'
import 'animate.css'
import DesktopApp from './DesktopApp'
import './helpers/i18n'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'
import MobileApp from './MobileApp'
import storage from 'local-storage'

// set log level
try {
	const loglevel = storage.get('loglevel')
	if (loglevel) {
		log.setLevel(loglevel, false)
	} else {
		log.disableAll()
	}
} catch (err) {
	log.disableAll()
}

ReactDOM.render(
	<Router>
		<Suspense fallback={<Preloader />}>
			<React.StrictMode>
				<Provider store={store}>
					<Routes>
						<Route path="/" element={<DesktopApp />} />
						<Route path="/m/*" element={<MobileApp />} />
					</Routes>
				</Provider>
			</React.StrictMode>
		</Suspense>
	</Router>,
	document.getElementById('root'),
)
