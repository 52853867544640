import actionTypes from '../types/actionTypes'
import { setLocalStorage } from '../../helpers/helpers'

const chatDataReducer = {
	[actionTypes.SET_CHAT_DATA]: (state, action) => {
		state.chat = {
			...state.chat,
			...action.payload,
		}
	},
	[actionTypes.OPEN_CHAT]: (state) => {
		state.chat.open = true
		setLocalStorage('chatIsOpen', true)
		if (state.userdata.auth) {
			setLocalStorage('chatIsOpen', true)
		}
	},
	[actionTypes.CLOSE_CHAT]: (state) => {
		state.chat.open = false
		if (state.userdata.auth) {
			setLocalStorage('chatIsOpen', false)
		}
	},
	[actionTypes.TOGGLE_INPUT_LANG]: (state) => {
		state.chat.inputGeo = !state.chat.inputGeo
	},
}

export default chatDataReducer
