import cx from 'classnames'
import st from '../../scss/global.module.scss'
import { cashoutAll } from '../../store/actions/ticketActions'
import React, { memo, useEffect, useState } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import { ticketStates } from '../../store/types'
import { toMoney } from '../../helpers/helpers'
import PropTypes from 'prop-types'

const CashoutAllBtn = memo(({ mob }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const tickets = useSelector((state) => state?.tickets, shallowEqual)
	const coef = useSelector((state) => state?.gameState?.coef)
	const [cashout, setCashout] = useState(0)

	useEffect(() => {
		let totCashout = tickets
			.filter((tkt) => tkt.state === ticketStates.TICKET_ACCEPTED)
			.reduce((prev, curr) => {
				return prev + (!curr.std ? (coef - 1) * curr.bet : coef * curr.bet)
			}, 0)

		setCashout(totCashout)
	}, [tickets, coef])

	return (
		<div
			className={cx(st.btn, st.cashoutAll, {
				[st.active]: cashout > 0,
				[st.mobile]: mob,
			})}
			onClick={() => dispatch(cashoutAll())}
		>
			{t('translation:common.cashout_all')}
			<span>
				{toMoney(cashout)}
				<i className="icon-gel" />
			</span>
		</div>
	)
})

CashoutAllBtn.propTypes = {
	mob: PropTypes.bool,
}

CashoutAllBtn.defaultProps = {
	mob: false,
}
export default CashoutAllBtn
