import PropTypes from 'prop-types'
import tb from 'scss/tables.common.module.scss'
import { memo, useEffect, useRef } from 'react'
import { toMoney } from '../../helpers/helpers'
import cx from 'classnames'

const FairCheckRow = memo(({ data, isCurrentEdition, selected }) => {
	/** @type {React.MutableRefObject<HTMLTableRowElement>} */
	const ref = useRef()

	useEffect(() => {
		if (selected && ref.current) {
			ref.current.scrollIntoView({
				behavior: 'smooth',
				block: 'center',
			})
		}
	}, [selected])

	return (
		<tr
			className={cx(
				{ [tb.selected]: selected },
				{ [tb.current]: isCurrentEdition },
			)}
			ref={(rf) => {
				if (selected) ref.current = rf
			}}
		>
			<td className={tb.edition}>{data.editionId}</td>
			<td className={cx(tb.outcome, { [tb.high]: data.crash >= 1.9 })}>
				{isCurrentEdition ? (
					<span className={tb.tbaBtn}>?</span>
				) : data.crash ? (
					`${toMoney(data.crash)}v`
				) : (
					'-'
				)}
			</td>
			<td className={tb.key}>
				{data.salt ? (
					<input
						defaultValue={data.salt}
						onClick={(e) => e.target?.['select']()}
					/>
				) : (
					'-'
				)}
			</td>
			<td className={tb.hash}>
				<input
					defaultValue={data.hash}
					onClick={(e) => e.target?.['select']()}
				/>
			</td>
		</tr>
	)
})

FairCheckRow.displayName = 'FairCheckRow'
FairCheckRow.propTypes = {
	data: PropTypes.object.isRequired,
	isCurrentEdition: PropTypes.bool.isRequired,
	selected: PropTypes.bool.isRequired,
}
export default FairCheckRow
