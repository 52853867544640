import { Modal } from 'react-bootstrap'
import ModalApp from './DraggableModal'
import st from './Modal.module.scss'
import cx from 'classnames'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useCallback } from 'react'
import { setJackpotDrop } from '../../store/actions/baseActions'
import CountUp from 'react-countup'
import PropTypes from 'prop-types'
import { counterDigitsFormatter } from '../../helpers/helpers'
import { useTranslation } from 'react-i18next'

const ModalJackpot = ({ type }) => {
	const jackpot = useSelector((state) => state?.jackpot, shallowEqual)
	const dispatch = useDispatch()
	const onHide = useCallback(() => {
		dispatch(
			setJackpotDrop({
				visible: false,
			}),
		)
	}, [dispatch])
	const { t } = useTranslation()

	return (
		<ModalApp
			show={jackpot.drop.visible}
			className={st.jackpotModal}
			centered={true}
			backdrop={true}
			keyboard={true}
			onHide={onHide}
			draggable={false}
		>
			<Modal.Header closeButton closeVariant={'white'} onHide={onHide}>
				<Modal.Title id="contained-modal-title-vcenter">
					{t('translation:modals.jackpot.title')}!
				</Modal.Title>
			</Modal.Header>
			<Modal.Body>
				<h4 className={st.titleUp}>
					{t('translation:modals.jackpot.jackpot_drop', {
						jackpotName: t(
							`translation:common.jackpots.${
								jackpot.types[jackpot.drop.id]
							}`,
						),
					})}
				</h4>
				<h1 className={cx(st.jackpotNum, st.jackpotTotal)}>
					<CountUp
						start={0}
						end={jackpot.drop.amount / 100}
						duration={0.5}
						decimals={2}
						decimal={'.'}
						formattingFn={counterDigitsFormatter}
					/>
					<i className="icon-gel" />
				</h1>
				<div className={st.jackpotWinWrap}>
					<h4 className={st.titleDown}>
						{t('translation:modals.jackpot.won_part')}:
					</h4>
					<h1 className={cx(st.jackpotNum, st.jackpotWin)}>
						<CountUp
							start={0}
							end={jackpot.drop.win / 100}
							duration={0.5}
							decimals={2}
							decimal={'.'}
							formattingFn={counterDigitsFormatter}
						/>
						<i className="icon-gel" />
					</h1>
				</div>
			</Modal.Body>
			<Modal.Footer />
		</ModalApp>
	)
}
ModalJackpot.propTypes = {
	type: PropTypes.oneOf(['mob', 'web']),
}
ModalJackpot.defaultProps = {
	type: 'web',
}
export default ModalJackpot
