/**
 * @class Sidenav
 * @constructor
 */

export default class SideNavDrawer {
	openClass
	openTransitionClass
	closeTransitionCLass
	stateCallback

	/**
	 * @param wrapper {String | HTMLElement}
	 * @param sidenav {String | HTMLElement}
	 * @param content {String | HTMLElement}
	 * @param [hitArea] {number}
	 * @param [threshold] {number}
	 * @param openClass
	 * @param closeClass
	 * @param openClass
	 * @param closeClass
	 * @param openTransitionClass
	 * @param closeTransitionCLass
	 * @param stateCallback
	 */
	// constructor (wrapper, sidenav, content, hitArea = .35, threshold = 20) {
	constructor({
		wrapper,
		sidenav,
		content,
		hitArea,
		threshold,
		openClass,
		closeClass,
		openTransitionClass,
		closeTransitionCLass,
		stateCallback,
	}) {
		this.wrapper = wrapper
		this.content = content
		this.stateCallback = stateCallback
		this.closeTransitionCLass = closeTransitionCLass ?? 'close-started'
		this.openTransitionClass = openTransitionClass ?? 'open-started'
		this.openClass = openClass ?? 'open'
		this.closeClass = closeClass ?? 'close'
		this.hitAreaSlice = hitArea ?? 0.35
		this.sidenav = sidenav
		this.hitArea = window.innerWidth * this.hitAreaSlice
		this.threshold = threshold ?? 20
		this.width = this.sidenav.clientWidth
		this.isHitarea = false
		this._state = this.wrapper.classList.contains(this.openClass)
			? this.openClass
			: this.closeClass
		this.states = `${this.openClass} ${this.closeClass} ${this.openTransitionClass} ${this.closeTransitionCLass}`
		this.touchStart = 0
		// add window resize listener
		/* $(window).on('resize', (e) => {
			 this.updateSize()
		 })*/

		// touch start
		this.wrapper.addEventListener('touchstart', (e) => {
			this._state = this.wrapper.classList.contains(this.openClass)
				? this.openClass
				: this.closeClass
			let touch = e.touches[0] || e.changedTouches[0]
			this.touchStart = touch.pageX
			this.isHitarea = this.touchStart < this.hitArea
		})
		this.wrapper.addEventListener('touchmove', (e) => {
			let touch = e.touches[0] || e.changedTouches[0]
			let delta = touch.pageX - this.touchStart
			this.slide(delta)
		})
		this.wrapper.addEventListener('touchend', (e) => {
			let touch = e.touches[0] || e.changedTouches[0]
			let delta = touch.pageX - this.touchStart
			if (this.state === this.openTransitionClass) {
				this.state =
					Math.abs(delta) >= this.width / 4
						? this.openClass
						: this.closeClass
			} else if (this.state === this.closeTransitionCLass) {
				this.state =
					Math.abs(delta) >= this.width / 4
						? this.closeClass
						: this.openClass
			}
		})
	}

	/** @param st {String} */
	set state(st) {
		this._state = st
		// remove all classes
		this.states.split(' ').forEach((v) => {
			this.wrapper.classList.remove(v)
		})
		st.split(' ').forEach((v) => {
			this.wrapper.classList.add(v)
		})

		// state callback
		if (typeof this.stateCallback === 'function') {
			this.stateCallback(this.getStateString(st))
		}
	}

	get state() {
		return this._state
	}

	getStateString(state) {
		switch (state) {
			case this.openTransitionClass:
				return 'opening'
			case this.openClass:
				return 'open'
			case this.closeTransitionCLass:
				return 'closing'
			case this.closeClass:
				return 'close'
			default:
				return null
		}
	}

	slide(delta) {
		switch (this.state) {
			case this.closeClass:
				if (this.isHitarea && delta >= this.threshold) {
					this.state = this.openTransitionClass
					this.move(delta - this.threshold)
				}
				break
			case this.openTransitionClass:
				this.move(delta - this.threshold)
				break
			case this.openClass:
				if (delta < 0 && Math.abs(delta) > this.threshold) {
					this.state = this.closeTransitionCLass
					this.move(this.width + delta + this.threshold)
				}
				break
			case this.closeTransitionCLass:
				this.move(this.width + delta + this.threshold, 1)
				break
			default:
				return false
		}
	}

	move(delta) {
		this.content.style.transform = `translate3d(${Math.min(
			Math.max(delta, 0),
			this.width,
		)}px, 0, 0)`
	}

	updateSize() {
		this.hitArea = window.innerWidth * this.hitAreaSlice
	}
}
