import cx from 'classnames'
import st from '../../scss/global.module.scss'
import { submitTicketsAll } from '../../store/actions/ticketActions'
import React, { memo } from 'react'
import { useDispatch } from 'react-redux'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

const SubmitAllBtn = memo(({ mob }) => {
	const dispatch = useDispatch()
	const { t } = useTranslation()

	return (
		<div
			className={cx(st.btn, st.betAll, { [st.mobile]: mob })}
			onClick={() => dispatch(submitTicketsAll())}
		>
			{t('translation:common.place_all')}
		</div>
	)
})

SubmitAllBtn.propTypes = {
	mob: PropTypes.bool,
}

SubmitAllBtn.defaultProps = {
	mob: false,
}

export default SubmitAllBtn
