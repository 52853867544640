import 'scss/bootstrap.override.scss'
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-toastify/dist/ReactToastify.min.css'
import 'scss/styles.scss'
import Header from './components/Header/Header'
import Panels from './components/Panels/Panels'
import Panel from './components/Panels/Panel'
import panel from 'components/Panels/panels.module.scss'
import Tabs from './components/Tabs/Tabs'
import Tab from './components/Tabs/Tab'
import st from 'scss/global.module.scss'
import cx from 'classnames'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Ticket from './components/Ticket/Ticket'
import React, {
	Fragment,
	useCallback,
	useEffect,
	useRef,
	useState,
} from 'react'
import { closeChat, openChat } from './store/actions/baseActions'
import {
	getLocalStorage,
	getPossibleWin,
	registerJackpotSound,
	setLocalStorage,
	toMoney,
} from './helpers/helpers'
import ReactTooltip from 'react-tooltip'
import PlayersList from './components/PlayersList/PlayersList'
import { Resizable } from 'react-resizable'
import { userLogin } from './store/actions/apiActions'
import Graph from './components/Graph/Graph'
import History from './components/History/History'
import { gameStates } from './store/types'
import jackpotSoundFile from 'sounds/jackpot.mp3'

import {
	addTicket,
	crashTickets,
	createDefaultTicket,
	placeBet,
	resetBettors,
	reviewTicketsOnAuth,
	transformFreespinTickets,
} from './store/actions/ticketActions'
import FairCheck from './components/History/FairCheck'
import Modals from './components/Modals'
import ModalJackpot from './components/Modals/ModalJackpot'
import Guide from './components/Guide/Guide'
import { ToastContainer } from 'react-toastify'
import { useTranslation } from 'react-i18next'
import Footer from './components/Footer/Footer'
import Changelog from './components/Modals/Changelog'
import CashoutAllBtn from './components/BatchButtons/CashoutAllBtn'
import CancelAllBtn from './components/BatchButtons/CancelAllBtn'
import SubmitAllBtn from './components/BatchButtons/SubmitAllBtn'

function DesktopApp() {
	const tickets = useSelector((state) => state?.tickets, shallowEqual)
	const auth = useSelector((state) => state?.userdata.auth)
	const bettors = useSelector((state) => state?.bettors, shallowEqual)
	// const chatIsOpen = useSelector((state) => state?.chat.open)
	const chatIsOpen = false
	const gameState = useSelector((state) => state?.gameState.state)
	const balance = useSelector((state) => state?.userdata.balance, shallowEqual)
	const dualSession = useSelector((state) => state?.dualSession)
	const maxTicketCount = useSelector((state) => state?.limits.maxTicketCount)
	const { t } = useTranslation()

	const dispatch = useDispatch()
	const tktWrapRef = useRef()
	const [scrollVisible, setScrollVisible] = useState(false)
	const prevTktLength = useRef(0)
	const rightColRef = useRef()
	const isVirtualBalance = balance.virtual >= 0.01

	const [playerPanelSize, setPlayerPanelSize] = useState({
		width: 200,
		height: 300,
	})
	const handleResize = useCallback((e, { size }) => {
		setPlayerPanelSize(size)
		// store size
		setLocalStorage('panelSize', size.height)
	}, [])
	const [ticketCollScrolled, setTicketCollScrolled] = useState(false)
	const [ticketScrollBottom, setTicketScrollBottom] = useState(true)
	const ticketsScrollHandler = useCallback((e) => {
		setTicketCollScrolled(e.target.scrollTop > 2)
		setTicketScrollBottom(
			e.target.scrollTop + e.target.clientHeight === e.target.scrollHeight,
		)
	}, [])

	const [colSize, setColSize] = useState({
		width: 0,
		height: 0,
	})

	useEffect(() => {
		if (!tickets.length) {
			dispatch(createDefaultTicket())
		}
		// set scroll Class
		setScrollVisible(
			tktWrapRef.current?.scrollHeight > tktWrapRef.current?.clientHeight,
		)

		// scroll tickets if new added
		if (prevTktLength.current < tickets.length) {
			tktWrapRef.current?.scrollTo({
				top: tktWrapRef.current?.scrollHeight,
				behavior: 'smooth',
			})
		}
	}, [dispatch, prevTktLength, tickets.length])

	// save tickets prev count
	useEffect(() => {
		prevTktLength.current = tickets.length

		// change col scroll shadows
		if (
			tktWrapRef.current?.scrollHeight === tktWrapRef.current?.clientHeight
		) {
			setTicketScrollBottom(true)
		}
	}, [tickets.length])

	// login
	useEffect(() => {
		if (!auth) {
			dispatch(userLogin())
		}
		// review tickets
		dispatch(reviewTicketsOnAuth())
	}, [dispatch, auth])

	// get rightCol height
	useEffect(() => {
		const { width, height } = rightColRef.current?.getBoundingClientRect()
		setColSize({ width, height })

		// register jackpot sound
		registerJackpotSound(jackpotSoundFile)
	}, [])

	// submit bets on state change
	useEffect(() => {
		switch (gameState) {
			case gameStates.ST_CREATE_EDITION:
				dispatch(transformFreespinTickets())
				break
			case gameStates.ST_START_BET:
				dispatch(placeBet())
				break
			case gameStates.ST_CRASH:
				dispatch(crashTickets())
				break
			default:
				break
		}
	}, [dispatch, gameState, tickets])

	// reset bettors
	useEffect(() => {
		if (gameState === gameStates.ST_START_BET) {
			dispatch(resetBettors())
		}
	}, [dispatch, gameState])

	// set stored data
	useEffect(() => {
		// set stored panel size
		const panelSize = getLocalStorage('panelSize')
		if (panelSize)
			setPlayerPanelSize((prev) => ({
				width: prev.width,
				height: panelSize,
			}))

		// set stored chat state

		if (!auth) {
			dispatch(closeChat())
		} else {
			const chatIsOpenStored = getLocalStorage('chatIsOpen')
			dispatch(chatIsOpenStored ? openChat() : closeChat())
		}
	}, [auth, dispatch])

	// noinspection JSValidateTypes
	return (
		<div className="App">
			<Header />
			<Panels>
				<Panel className={panel.leftCol}>
					<Panel className={panel.chartPanel}>
						<Graph />
					</Panel>
					<Panel className={panel.tabsPanel}>
						<Tabs>
							<Tab
								id={'history'}
								title={t('common.history')}
								icon={'list'}
							>
								<History />
							</Tab>
							{/*<Tab id={'statistics'} title={ t('translation:common.stats') } icon={'chart'}>*/}
							{/*	{ t('translation:common.stats') }*/}
							{/*</Tab>*/}
							<Tab
								id={'check'}
								title={t('common.check_edition')}
								icon={'check'}
							>
								<FairCheck />
							</Tab>
							<Tab id={'help'} title={t('common.help')} icon={'help'}>
								<Guide />
							</Tab>
						</Tabs>
						<Footer />
					</Panel>
				</Panel>
				<Panel className={panel.middleCol}>
					<div
						className={cx(st.betColCap, {
							[st.scrolled]: ticketCollScrolled,
						})}
					/>
					<div
						className={cx(st.betTickets, {
							scrollVisible: scrollVisible,
						})}
					>
						<div
							className={st.betTicketsInner}
							ref={tktWrapRef}
							onScroll={ticketsScrollHandler}
						>
							{tickets.map((ticketData, index) => (
								<Ticket data={ticketData} key={index} />
							))}

							{tickets.length < maxTicketCount && (
								<div
									className={st.addTicketCustomBtn}
									onClick={() => {
										dispatch(addTicket())
									}}
								>
									<i className="icon-add" />
									<div>{t('translation:common.add_ticket')}</div>
								</div>
							)}
						</div>
					</div>
					<div
						className={cx(st.betHeader, {
							[st.scrolled]: !ticketScrollBottom,
						})}
					>
						<CancelAllBtn />
						<SubmitAllBtn />
						<CashoutAllBtn />
					</div>
					<div className={st.betFooter}>
						<div
							className={cx(st.infoPanel, {
								[st.wide]: !isVirtualBalance,
							})}
						>
							<div className={st.infoItem}>
								<div className={st.title}>
									{t('translation:common.tickets')}
								</div>
								<div className={st.value}>
									<i className={cx('icon-ticket', st.ticketIcon)} />
									{tickets.length}/{maxTicketCount}
								</div>
							</div>
							<div className={st.infoItem}>
								<div className={st.title}>
									{t('translation:common.total_bet')}
								</div>
								<div className={st.value}>
									<i
										className={cx(
											'icon-currency',
											'icon-gel',
											st.iconCurrency,
										)}
									/>
									{toMoney(
										tickets.reduce(
											(cur, next) => Number(cur) + Number(next.bet),
											0,
										),
									)}
								</div>
							</div>
							{!isVirtualBalance && (
								<div
									className={st.infoItem}
									data-tip={`
							        <div class="tipWrapper">
							            <i class="icon-help"></i>
							            <div class="tip-text">
							                <div class="tip-text-title">${t(
														'translation:messages.possible_win.title',
													)}</div>
							                <div class="tip-text-body">
							                    ${t('translation:messages.possible_win.body')}
							                </div>
										</div>
									</div>
							     `}
									data-for={'possibleWinTip'}
								>
									<div className={st.title}>
										{t('translation:messages.possible_win.title')}
									</div>
									<div className={st.value}>
										<i
											className={cx(
												'icon-currency icon-gel',
												st.iconCurrency,
											)}
										/>
										{getPossibleWin(tickets)}
									</div>
								</div>
							)}

							<ReactTooltip
								id={'possibleWinTip'}
								place={'top'}
								effect={'solid'}
								offset={{ top: '-5px' }}
								multiline={true}
								backgroundColor={'rgba(0, 0, 0, .8)'}
								html={true}
								className={st.possibleWinTip}
							/>
						</div>
						<div
							className={cx(st.btn, st.addTicket)}
							onClick={() => dispatch(addTicket())}
						>
							<i className={'icon-add'} />
							{t('translation:common.regular_ticket')}
						</div>
						{isVirtualBalance && (
							<div
								className={cx(
									st.btn,
									st.addTicket,
									st.addVirtualTicket,
								)}
								onClick={() => dispatch(addTicket({ virtual: true }))}
							>
								<i className={'icon-add'} />
								{t('translation:common.freespin_ticket')}
							</div>
						)}
					</div>
				</Panel>
				<Panel className={panel.rightCol} ref={rightColRef}>
					<Resizable
						height={playerPanelSize.height}
						width={playerPanelSize.width}
						onResize={handleResize}
						resizeHandles={['se']}
						axis={'y'}
						minConstraints={[colSize.width, 200]}
						maxConstraints={[colSize.width, colSize.height * 0.8]}
						handle={
							<div
								className={cx(st.panelsResizeHandle, {
									[st.hidden]: !chatIsOpen,
								})}
							/>
						}
					>
						<Fragment>
							<Panel
								className={cx(panel.playersPanel, {
									[panel.expanded]: !chatIsOpen,
								})}
								style={{ height: playerPanelSize.height }}
							>
								<PlayersList data={bettors} />
							</Panel>
						</Fragment>
					</Resizable>
					{/*<Panel*/}
					{/*	className={cx(panel.chatPanel, { [st.hidden]: !chatIsOpen })}*/}
					{/*>*/}
					{/*	<Chat />*/}
					{/*</Panel>*/}
					{/*{!chatIsOpen && (*/}
					{/*	<Panel*/}
					{/*		className={panel.chatClosedPanel}*/}
					{/*		onClick={() => {*/}
					{/*			if (auth) {*/}
					{/*				dispatch(openChat())*/}
					{/*			}*/}
					{/*		}}*/}
					{/*	>*/}
					{/*		<i className={'icon-chat-closed'} />*/}
					{/*		{auth*/}
					{/*			? t('translation:common.open_chat')*/}
					{/*			: t('translation:common.chat_auth_blocked')}*/}
					{/*	</Panel>*/}
					{/*)}*/}
				</Panel>
			</Panels>
			<Modals>
				<ModalJackpot />
				<Changelog />
			</Modals>
			<ToastContainer
				position={'bottom-left'}
				theme={'colored'}
				draggablePercent={10}
				toastClassName={st.toastMessage}
			/>
			{dualSession && (
				<div className={'dual-session-window'}>
					<div>{t('translation:messages.dual_session')}</div>
				</div>
			)}
		</div>
	)
}

export default DesktopApp
