import React, { memo } from 'react'
import cx from 'classnames'
import st from 'scss/Mobile.module.scss'
import { useTranslation } from 'react-i18next'
import useNav from '../../../hooks/useNav'
import CashoutAllBtn from '../../BatchButtons/CashoutAllBtn'
import SubmitAllBtn from '../../BatchButtons/SubmitAllBtn'
import CancelAllBtn from '../../BatchButtons/CancelAllBtn'

const BatchBetButton = memo((props) => {
	const { location, navigate } = useNav()
	const { t } = useTranslation()

	return (
		<div className={st.batchBetButtons}>
			{location.pathname === '/m' ? (
				<CancelAllBtn mob={true} />
			) : (
				<div
					className={cx(st.batchBtn, st.backHome)}
					onClick={() => navigate('')}
				>
					{t('translation:common.game')}
				</div>
			)}
			{/*<div className={cx(st.batchBtn, st.betAll)} onClick={() => dispatch(submitTicketsAll())}>{t('translation:common.place_all')}</div>*/}
			<SubmitAllBtn mob={true} />
			<CashoutAllBtn mob={true} />
		</div>
	)
})
export default BatchBetButton
