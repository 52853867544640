import PropTypes from 'prop-types'
import st from './TicketButton.module.scss'
import cx from 'classnames'
import buttonTypes from './buttonTypes'
import {Fragment, memo} from 'react'
import Loader from '../Loader/Loader'
import { useSelector } from 'react-redux'
import { useTranslation } from 'react-i18next'
import BetCashoutButton from "./BetCashoutButton";

const TicketButton = memo((props) => {
	const ticketsCount = useSelector((state) => state?.tickets.length)

	const { t } = useTranslation()

	return (
		<Fragment>
			<div
				onClick={props.onClick}
				className={cx(
					st.actionButton,
					{
						[st.placeBet]: props.type === buttonTypes.PLACE_BET,
						[st.cancelBet]: props.type === buttonTypes.CANCEL_BET,
						[st.betAccepted]: props.type === buttonTypes.BET_ACCEPTED,
						[st.betLoader]: props.type === buttonTypes.BET_LOADER,
						[st.betCashout]: props.type === buttonTypes.BET_CASHOUT,
						[st.disabled]: props.disabled,
						[st.mobile]: props.mobile,
					},
					{ [st.virtual]: props.virtual },
				)}
			>
				{props.type === buttonTypes.PLACE_BET
					? props.virtual
						? t('translation:common.place_freevolt')
						: t('translation:common.place_bet')
					: null}
				{props.type === buttonTypes.CANCEL_BET &&
					t('translation:common.cancel_bet')}
				{props.type === buttonTypes.BET_ACCEPTED && (
					<Fragment>
						<div className={st.btnLabel}>
							{t('translation:common.bet_accepted')}
						</div>
						<Loader />
					</Fragment>
				)}
				{props?.type === buttonTypes.BET_LOADER && <Loader />}
				{props?.type === buttonTypes.BET_CASHOUT && (
					<BetCashoutButton bet={props?.bet} virtual={props?.virtual} />
				)}
			</div>

			{ticketsCount > 1 && props.type === buttonTypes.PLACE_BET && (
				<div className={st.ticketRemoveButton} onClick={props.removeAction}>
					<i className={'icon-tkt-remove'} />
				</div>
			)}
		</Fragment>
	)
})

TicketButton.displayName = 'TicketButton'

TicketButton.propTypes = {
	type: PropTypes.oneOf([...Object.values(buttonTypes)]),
	onClick: PropTypes.func,
	removeAction: PropTypes.func,
	disabled: PropTypes.bool,
	bet: PropTypes.number,
	virtual: PropTypes.bool,
	mobile: PropTypes.bool,
}
TicketButton.defaultProps = {
	disabled: false,
	virtual: false,
	mobile: false,
}

export default TicketButton
