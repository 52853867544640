import actionTypes from '../types/actionTypes'

const gifDataReducer = {
	[actionTypes.SET_GIF_LOADING]: (state) => {
		state.chat.gifApi.loading = true
	},
	[actionTypes.FETCH_GIFS]: (state, action) => {
		const pagination = action.payload?.pagination
		state.chat.gifApi.loading = false
		state.chat.gifItems = action.payload.data
		state.chat.gifPages = {
			count: pagination?.count || 25,
			offset: pagination?.offset || 0,
			totalCount: pagination?.total_count || 0,
		}
	},
	[actionTypes.GIF_FIRST_PAGE]: (state) => {
		if (!state.chat.gifApi.loading) {
			state.chat.gifPages.offset = 0
		}
	},
	[actionTypes.GIF_PREV_PAGE]: (state) => {
		if (!state.chat.gifApi.loading) {
			const offset = state.chat.gifPages.offset - state.chat.gifApi.limit
			state.chat.gifPages.offset = offset < 0 ? 0 : offset
		}
	},
	[actionTypes.GIF_NEXT_PAGE]: (state) => {
		if (!state.chat.gifApi.loading) {
			const offset = state.chat.gifPages.offset + state.chat.gifApi.limit
			state.chat.gifPages.offset =
				offset < state.chat.gifPages.totalCount
					? offset
					: state.chat.gifPages.totalCount - state.chat.gifApi.limit
		}
	},
	[actionTypes.GIF_SEARCH]: (state, action) => {
		if (!state.chat.gifApi.loading) {
			state.chat.gifApi.search = action.payload
			state.chat.gifPages.offset = 0
		}
	},
}

export default gifDataReducer
