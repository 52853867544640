import PropTypes from 'prop-types'
import tb from 'scss/tables.common.module.scss'
import React, { memo, useEffect, useMemo, useState } from 'react'
import { convertCoin, toMoney } from '../../helpers/helpers'
import cx from 'classnames'
import { useDispatch } from 'react-redux'
import { checkEdition } from '../../store/actions/baseActions'
import st from '../../scss/global.module.scss'
import gb from '../../scss/global.module.scss'
import ReactTooltip from 'react-tooltip'
import { useTranslation } from 'react-i18next'
import MultiBetRowItem from './MultiBetRowItem'

const HistoryRow = memo(({ data, isCurrentEdition, type, navigate }) => {
	const { bets } = data
	const dispatch = useDispatch()
	const { t } = useTranslation()
	const [expanded, setExpanded] = useState(!!isCurrentEdition)

	useEffect(() => {
		setExpanded(!!isCurrentEdition)
	}, [isCurrentEdition])

	const ticketLimitMessage = useMemo(
		() => `
		<div class="tipWrapper">
			<i class="icon-attention"></i>
			<div class="tip-text">
				<div class="tip-text-title">${t(
					'translation:messages.ticket_limit.title',
				)}</div>
				<div class="tip-text-body">
					${t('translation:messages.ticket_limit.body')}
				</div>
			</div>
		</div>
	`,
		[t],
	)

	// multi bet
	if (bets.length > 1) {
		const totalBet = bets.reduce((prev, curr) => {
			return prev + curr.bet
		}, 0)
		const totalWin = bets.reduce((prev, curr) => {
			return prev + curr.profit
		}, 0)

		return (
			<tr className={cx({ [tb.mobile]: type === 'mob' })}>
				<td colSpan="6" className={tb.multiBet}>
					<table
						className={cx(tb.multiBetTable, {
							[tb.expanded]: expanded,
							[tb.collapsed]: !expanded,
							[tb.currentEdition]: isCurrentEdition,
						})}
						onClick={(e) => {
							if (!isCurrentEdition) {
								setExpanded(!expanded)
							}
						}}
					>
						<colgroup>
							<col className={tb.edition} />
							<col className={tb.outcome} />
							<col className={tb.cashout} />
							<col className={tb.bet} />
							<col className={tb.win} />
							<col className={tb.check} />
						</colgroup>
						<thead>
							<tr>
								<th className={tb.edition}>{data.editionId}</th>
								<th
									className={cx(tb.outcome, {
										[tb.high]: data.crash >= 1.9,
									})}
								>
									{isCurrentEdition ? (
										<span className={tb.tbaBtn}>?</span>
									) : data.crash ? (
										`${toMoney(data.crash)}v`
									) : (
										'-'
									)}
								</th>
								<th className={tb.cashout}>
									<span />
								</th>
								<th className={tb.bet}>
									{totalBet ? (
										<span>
											<i className={'icon-currency icon-gel'} />
											{convertCoin(totalBet)}
										</span>
									) : (
										'-'
									)}
								</th>
								<th className={tb.win}>
									{totalWin ? (
										<span>
											<i className={'icon-currency icon-gel'} />
											{convertCoin(totalWin)}
										</span>
									) : (
										'-'
									)}
								</th>
								<th className={tb.check}>
									<span
										className={tb.checkEdition}
										onClick={(e) => {
											dispatch(
												checkEdition({
													editionId: data.editionId,
													tabId: 'check',
												}),
											)
											if (type === 'mob') {
												navigate('/m/check')
											}

											e.stopPropagation()
											e.preventDefault()
										}}
									>
										<span className={tb.checkBtnText}>
											{t('translation:common.check')}
										</span>
										<i className={'icon-check'} />
									</span>
								</th>
							</tr>
						</thead>
						<tbody>
							{expanded &&
								bets.map((bet, ix) => (
									<MultiBetRowItem
										key={ix}
										bet={bet}
										message={ticketLimitMessage}
										ix={ix}
									/>
								))}
						</tbody>
					</table>
				</td>
			</tr>
		)
	}
	// single bet
	else {
		const bet = bets[0] ? bets[0] : { coef: null, bet: null, profit: null }
		return (
			<tr className={cx({ [tb.mobile]: type === 'mob' })}>
				<td className={tb.edition}>{data.editionId}</td>
				<td className={cx(tb.outcome, { [tb.high]: data.crash >= 1.9 })}>
					{isCurrentEdition ? (
						<span className={tb.tbaBtn}>?</span>
					) : data.crash ? (
						`${toMoney(data.crash)}v`
					) : (
						'-'
					)}
				</td>
				<td className={tb.cashout}>
					{bet?.forcedCoef > 0 ? (
						<>
							<span
								className={st.tktCashoutLimit}
								data-tip={ticketLimitMessage}
								data-for={'tktLimitTip'}
							>
								<i
									className={cx('icon-attention', tb.tktLimitWarning)}
								/>
								{bet.coef ? `${toMoney(bet.coef)}v` : '-'}
							</span>
							<ReactTooltip
								id={'tktLimitTip'}
								place={'top'}
								effect={'solid'}
								offset={{ top: '-5px' }}
								multiline={true}
								backgroundColor={'rgba(0, 0, 0, .8)'}
								html={true}
								className={gb.toolTipStyle}
							/>
						</>
					) : bet.coef ? (
						`${toMoney(bet.coef)}v`
					) : (
						'-'
					)}
				</td>
				<td className={tb.bet}>
					{bet.bet ? (
						<span
							className={cx({
								[tb.freeVolt]: bet.currencyType === 'VIRTUAL',
							})}
						>
							<i
								className={cx('icon-currency', {
									'icon-volt': bet.currencyType === 'VIRTUAL',
									'icon-gel': bet.currencyType === 'REAL',
								})}
							/>
							{convertCoin(bet.bet)}
						</span>
					) : (
						'-'
					)}
				</td>
				<td className={tb.win}>
					{bet.profit ? (
						<span>
							<i className={'icon-currency icon-gel'} />
							{convertCoin(bet.profit)}
						</span>
					) : (
						'-'
					)}
				</td>
				<td className={tb.check}>
					<span
						className={tb.checkEdition}
						onClick={(e) => {
							dispatch(
								checkEdition({
									editionId: data.editionId,
									tabId: 'check',
								}),
							)
							if (type === 'mob' && typeof navigate === 'function') {
								navigate('/check')
							}
							e.stopPropagation()
							e.preventDefault()
						}}
						title={t('translation:common.check')}
					>
						<span className={tb.checkBtnText}>
							{t('translation:common.check')}
						</span>
						<i className={'icon-check'} />
					</span>
				</td>
			</tr>
		)
	}
})

HistoryRow.displayName = 'HistoryRow'
HistoryRow.propTypes = {
	data: PropTypes.object.isRequired,
	isCurrentEdition: PropTypes.bool.isRequired,
	type: PropTypes.oneOf(['mob', 'web']),
	navigate: PropTypes.func,
}

HistoryRow.defaultProps = {
	type: 'web',
}
export default HistoryRow
