export class Chart {
	constructor(width = 0, height = 0, type = 'web') {
		this.resize(width, height)

		// initial values
		this.xAxisPlotMinValue = 10000
		this.yAxisSizeMultiplier = 2
		this.lastTime = 0
		this.actors = []
		this.elapsedTime = 0
		this.ctx = null
		this.currentTime = 0
		this.lastBalance = 0
		this.cashoutBrakes = []
		this.type = type
	}

	resize(width, height) {
		this.width = width
		this.height = height

		this.plotWidth = this.width - 30
		this.plotHeight = this.height - 20
		this.xStart = this.width - this.plotWidth
		this.yStart = this.plotHeight
	}

	runAnimationActors(time) {
		let delta = time - this.lastTime,
			index = this.actors.length - 1,
			actor = this.actors[index]

		if (actor) {
			if (actor(this.elapsedTime)) {
				this.actors.splice(0, index + 1)
			}
			this.elapsedTime += delta
		}

		this.lastTime = time
		requestAnimationFrame(this.runAnimationActors.bind(this))
	}

	setContext(ctx) {
		this.ctx = ctx
	}

	setData(time, coef) {
		this.currentTime = time
		this.lastBalance = coef
	}

	clean() {
		if (this.ctx) {
			this.ctx.clearRect(0, 0, this.width, this.height)
		}
	}

	reset() {
		this.elapsedTime = 0
		this.actors = []
		this.clean()
	}

	calculatePlotValues() {
		//plot variables
		this.yAxisPlotMinValue = this.yAxisSizeMultiplier

		//start values
		this.xAxisPlotValue = this.xAxisPlotMinValue
		this.yAxisPlotValue = this.yAxisPlotMinValue

		//axis max values
		this.xAxisPlotValue = Math.max(this.currentTime, this.xAxisPlotMinValue)
		this.yAxisPlotValue = Math.max(this.lastBalance, this.yAxisPlotMinValue)

		//move y down by 1 - start from 1
		this.yAxisPlotValue -= 1

		//chart axis units
		this.xUnit = this.plotWidth / this.xAxisPlotValue
		this.yUnit = this.plotHeight / this.yAxisPlotValue
	}

	calcCoef(elapsed) {
		return Math.floor(100 * Math.pow(Math.E, 0.00006 * elapsed)) / 100
	}

	drawGraph() {
		let time, calcY, x, y, i

		// save last xy
		let _lx = 0
		let _ly = this.plotHeight

		// snapshot cashout brakes at the beginning of draw
		let _cashoutBrakes = {}
		this.cashoutBrakes.map((x) => {
			if (!_cashoutBrakes[x]) {
				_cashoutBrakes[x] = false
			}
			return x
		})

		// draw line
		for (time = 0, i = 0; time <= this.currentTime; time += 100, i++) {
			// calc y val by time
			calcY = this.calcCoef(time) - 1

			// move cursor
			this.ctx.beginPath()
			this.ctx.moveTo(this.xStart + _lx, _ly)

			// set line join cap & style
			this.ctx.lineWidth = 3
			this.ctx.lineJoin = 'round'
			this.ctx.strokeStyle = '#FFF330'

			// cal x y
			x = time * this.xUnit
			y = this.plotHeight - calcY * this.yUnit

			// draw line
			this.ctx.lineTo(this.xStart + x, y)

			// save last x y
			_lx = x
			_ly = y

			// confirm line draw
			this.ctx.closePath()
			this.ctx.stroke()

			// DRAW CASHOUT POINTER
			// --------------------

			// set pointer color
			let pointerColor = '#32FA93'

			// loop though brakes
			// eslint-disable-next-line no-loop-func
			Object.keys(_cashoutBrakes).map((brakeCoef) => {
				if (calcY + 1 >= brakeCoef * 1 && !_cashoutBrakes[brakeCoef]) {
					_cashoutBrakes[brakeCoef] = true

					//draw line
					this.ctx.beginPath()
					this.ctx.lineWidth = 1
					this.ctx.strokeStyle = pointerColor
					this.ctx.moveTo(this.xStart + x, y - 6)
					this.ctx.lineTo(this.xStart + x, y + 6)
					this.ctx.closePath()
					this.ctx.stroke()

					//draw pointer cap
					this.ctx.beginPath()
					this.ctx.lineWidth = 1
					this.ctx.fillStyle = pointerColor
					this.ctx.lineTo(this.xStart + x - 3, y - 4 - 5)
					this.ctx.lineTo(this.xStart + x + 3, y - 4 - 5)
					this.ctx.lineTo(this.xStart + x, y + 4)
					this.ctx.closePath()
					this.ctx.fill()
				}
				return brakeCoef
			})
		}
	}

	drawAxes() {
		//inner function to calculate plotting values of Axis
		function stepValues(x) {
			let c = 0.4
			let r = 0.1

			while (true) {
				if (x < c) return r

				c *= 5
				r *= 2

				if (x < c) return r

				c *= 2
				r *= 5
			}
		}

		//calculate y axis
		this.payoutSeparation = stepValues(
			!this.lastBalance ? 1 : this.lastBalance,
		)

		//style for y axis
		this.ctx.lineWidth = 1
		this.ctx.strokeStyle = '#576071'
		this.ctx.font = 'bold 12px Consolas, Menlo'
		this.ctx.fillStyle = '#858FA5'

		//draw y axis values
		let heightIncrement = this.plotHeight / this.yAxisPlotValue
		for (
			let payout = this.payoutSeparation, i = 0;
			payout < this.yAxisPlotValue;
			payout += this.payoutSeparation, i++
		) {
			let y = this.plotHeight - payout * heightIncrement

			//y label
			let yLabel = payout + 1
			if (yLabel > 20 && yLabel < 1000) yLabel -= 1
			if (yLabel > 1000) yLabel = `${(yLabel - 1) / 1000}K`

			//styles
			this.ctx.fillText(`${yLabel}v`, 0, y)
			this.ctx.beginPath()
			this.ctx.moveTo(this.xStart, y)
			this.ctx.lineTo(this.xStart + 5, y)
			this.ctx.stroke()
		}

		//calculate x axis
		this.millisecondsSeparation = stepValues(this.xAxisPlotValue)
		this.xAxisValuesSeparation =
			this.plotWidth / (this.xAxisPlotValue / this.millisecondsSeparation)

		//draw x axis values
		for (
			let milliseconds = 0, counter = 0, i = 0;
			milliseconds < this.xAxisPlotValue;
			milliseconds += this.millisecondsSeparation, counter++, i++
		) {
			let seconds = milliseconds / 1000
			this.textWidth = this.ctx.measureText(seconds.toString()).width
			let x = counter * this.xAxisValuesSeparation + this.xStart
			this.ctx.fillText(
				seconds.toString(),
				x - this.textWidth / 2,
				this.plotHeight + 16,
			)
		}

		//draw background axis
		this.ctx.lineWidth = 1
		this.ctx.beginPath()
		this.ctx.moveTo(this.xStart, 0)
		this.ctx.lineTo(this.xStart, this.yStart + 3)
		this.ctx.lineTo(this.width, this.yStart + 3)
		this.ctx.stroke()
	}

	drawGameData() {
		let fontSize = this.type === 'mob' ? 64 : 100
		let compRatio = 0.8
		let minFontSize = 42

		// TODO: bet color
		this.ctx.fillStyle = '#FFF'
		this.ctx.font = `bold ${fontSize}px Consolas, Menlo`

		let val = `${parseFloat(this.lastBalance).toFixed(2)}v`
		let textWidth = this.ctx.measureText(val).width

		// resize text if bigger value
		if (textWidth > this.width * compRatio) {
			fontSize = (2 - textWidth / (this.width * compRatio)) * fontSize
			fontSize = Math.max(minFontSize, fontSize)
			this.ctx.font = `bold ${fontSize.toFixed(0)}px Consolas, Menlo`
			textWidth = this.ctx.measureText(val).width
		}

		//draw text
		let fontY = this.height / 2 + (fontSize * 0.65) / 2 - 15
		this.ctx.fillText(val, (this.width - textWidth) / 2 + 15, fontY)
	}

	drawChart(time, coef) {
		this.setData(time, coef)
		this.clean()
		this.calculatePlotValues()
		this.drawGraph()
		this.drawAxes()
		this.drawGameData()
	}

	updateChart(time, coef) {
		if (!this.elapsedTime) {
			this.elapsedTime = time
		} else {
			if (time - this.elapsedTime > 500) {
				this.elapsedTime = time
			}
		}

		this.actors.push((elapsed) => {
			// double progress = Math.floor(100 * Math.pow(Math.E, 0.00006 * (1.5 * elapsed))) / 100d;
			// double coef = Math.min(Math.floor(progress * 100d) / 100d, edition.getCrash());
			const progress = Math.pow(Math.E, 0.00006 * (1.5 * elapsed))
			this.drawChart(1.5 * Math.min(time, elapsed), Math.min(progress, coef))
			return elapsed >= time
		})
	}
}
