import { createReducer } from '@reduxjs/toolkit'
import initialStore from '../initialStore'
import userDataReducer from './userData'
import uiDataReducer from './uiData'
import ticketDataReducer from './ticketData'
import chatDataReducer from './chatData'
import gifDataReducer from './gifData'
import gameDataReducer from './gameData'
import mobileDataReducer from './mobileData'

const rootReducer = createReducer(initialStore, {
	...userDataReducer,
	...uiDataReducer,
	...ticketDataReducer,
	...chatDataReducer,
	...gifDataReducer,
	...gameDataReducer,
	...mobileDataReducer,
})

export default rootReducer
