import st from './Graph.module.scss'
import { useResizeDetector } from 'react-resize-detector/build/withPolyfill'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import { useEffect, useRef, useState } from 'react'
import { Chart } from './Chart'
import cx from 'classnames'
import { gameStates } from '../../store/types'
import { CSSTransition } from 'react-transition-group'
import { gameLoop, toMoney } from '../../helpers/helpers'
import PropTypes from 'prop-types'
import { useTranslation } from 'react-i18next'

import store from 'store'
import NetworkIcon from './NetworkIcon'

const Graph = ({ type }) => {
	const gameState = useSelector((store) => store?.gameState, shallowEqual)
	const dispatch = useDispatch()
	const canvasRef = useRef()
	const pgBarRef = useRef()
	const { width, height, ref } = useResizeDetector({
		refreshMode: 'debounce',
		refreshRate: 100,
	})
	/** @type {React.MutableRefObject<Chart>} */
	const chart = useRef(null)
	const [size, setSize] = useState({
		width: 0,
		height: 0,
	})
	const [pgStartSize, setPgStartSize] = useState(0)
	const [duration, setDuration] = useState(0)
	const { t } = useTranslation()

	// update chart size
	useEffect(() => {
		setSize({ width, height })

		if (chart.current && canvasRef.current) {
			chart.current.resize(width, height)
			canvasRef.current.width = width
			canvasRef.current.height = height
		}
	}, [dispatch, height, width, chart])

	// create chart on init (runs once on mount)
	useEffect(() => {
		if (!chart.current) {
			chart.current = new Chart(null, null, type)
			chart.current.setContext(canvasRef.current?.getContext('2d'))
			chart.current.runAnimationActors(0)
		}
	}, [type])

	// TODO: remove this test
	useEffect(() => {
		gameLoop(store.getState, store.dispatch)
	}, [])

	// update chart -> push new actors
	useEffect(() => {
		switch (gameState.state) {
			case gameStates.ST_START_BET:
				const percent = (gameState.elapsed / gameState.stateTimeout) * 100
				setPgStartSize(percent)
				break
			case gameStates.ST_GO_START:
				// gameLoop(store.getState, store.dispatch)
				// loopConfig.test = 'bbb'
				break
			case gameStates.ST_GO_GO:
				// console.log('gameState.elapsed', gameState.elapsed, gameState.coef)
				chart.current.updateChart(gameState.elapsed, gameState.coef)
				break
			case gameStates.ST_CRASH:
				chart.current.reset()
				break
			default:
				break
		}

		if (gameState.state !== gameStates.ST_START_BET && !!pgStartSize) {
			setPgStartSize(0)
		}
	}, [
		gameState,
		gameState.coef,
		gameState.elapsed,
		gameState.state,
		pgStartSize,
	])

	return (
		<div
			className={cx(st.graphWrapper, { [st.mobile]: type === 'mob' })}
			ref={ref}
		>
			<canvas
				ref={canvasRef}
				className={cx({
					[st.hidden]: gameState.state !== gameStates.ST_GO_GO,
				})}
				style={{
					width: size.width,
					height: size.height,
				}}
			/>
			<div
				className={cx(st.crashMsg, {
					[st.hidden]: gameState.state !== gameStates.ST_CRASH,
				})}
			>
				<div className={st.text}>{t('messages.busted')}</div>
				<div className={st.value}>{toMoney(gameState.crash)}v</div>
			</div>
			<div
				className={cx(st.acceptingMsg, {
					[st.hidden]: gameState.state !== gameStates.ST_START_BET,
				})}
			>
				<div className={st.text}>{t('messages.accepting_bets')}</div>
				<div className={st.progress}>
					<CSSTransition
						timeout={{
							enter: 0,
							appear: gameState.stateTimeout - gameState.elapsed,
							exit: 0,
						}}
						in={gameState.state === gameStates.ST_START_BET}
						classNames={'progress'}
						nodeRef={pgBarRef}
						onEnter={() => {
							setDuration(0)
						}}
						onEntering={() => {
							setDuration(gameState.stateTimeout - gameState.elapsed)
						}}
					>
						<div
							className={st.progressBar}
							ref={pgBarRef}
							style={{
								width: `${pgStartSize}%`,
								transitionDuration: `${duration}ms`,
							}}
						/>
					</CSSTransition>
				</div>
			</div>
			<div
				className={cx(st.crashMsg, {
					[st.hidden]: gameState.state !== gameStates.ERROR,
				})}
			>
				<div className={st.value}>😞</div>
			</div>
			<div className={st.netIconPreload} />
			<div
				className={cx(st.crashMsg, {
					[st.hidden]: gameState.state !== gameStates.NETWORK,
				})}
			>
				<div className={st.value}>
					<NetworkIcon />
				</div>
			</div>
		</div>
	)
}

Graph.propTypes = {
	type: PropTypes.oneOf(['mob', 'web']),
}
Graph.defaultProps = {
	type: 'web',
}
export default Graph
