import { ticketStates } from './types'

const initialStore = {
	connected: false,
	userdata: {
		auth: false,
		balance: {
			real: 0,
			virtual: 0,
		},
		username: 'test',
		pin: '123456',
		hash: '',
		token: '',
		jwt: {},
		uuid: '',
	},
	activeTabId: 'history',
	selectedEditionId: null,
	defaultTicket: {
		id: null,
		sid: null,
		std: true,
		bet: 5.0,
		autoBet: false,
		autoCashout: true,
		cashout: 10.0,
		minCashout: 1.01,
		defaultCashout: 10.0,
		minBet: 0.1,
		state: ticketStates.TICKET_IDLE,
	},
	tickets: [],
	bettors: [
		// {
		// 	id: '',
		// 	sid: '',
		// 	usrName: "zur4ik",
		// 	coef: 2.4,
		// 	bet: 1.5,
		// 	profit: 15.3,
		// 	isMine: false,
		// 	active: true
		// },
	],
	shortcuts: {
		bet: [1, 2, 5, 10, 'x2', 'all'],
		cashout: [2, 5, 10, 20, 100, 500],
	},
	chat: {
		open: false,
		inputLimit: 90,
		inputGeo: true,
		inputEnabled: true,
		inputExpanded: false,
		inputCursorPosition: 0,
		messages: [],
		activePanel: null,
		emojiActiveCat: null,
		emojiSearch: '',
		gifApi: {
			apiKey: 'GtGV7nWBvYx5r4haZJSGqHc4PXt7DyDZ',
			baseEndpoint: '//api.giphy.com/v1/gifs/trending',
			searchEndpoint: '//api.giphy.com/v1/gifs/search',
			limit: 25,
			loading: true,
			endpoint: null,
			search: '',
		},
		gifPages: {
			count: 20,
			offset: 0,
			totalCount: 0,
		},
		gifItems: [],
	},
	graph: {
		cashoutBrakes: [],
	},
	gameState: {
		coef: null,
		crash: null,
		editionId: null,
		elapsed: null,
		state: null,
		stateTimeout: null,
		time: null,
	},
	history: [],
	jackpot: {
		types: {
			2: 'kilowatt',
			3: 'megawatt',
			4: 'gigawatt',
		},
		data: [],
		animating: null,
		drop: {
			visible: false,
			amount: 0,
			win: 0,
			id: 0,
		},
	},
	mobile: {
		isNavOpen: false,
	},
	limits: {
		minBetPerTicket: 0.1,
		maxTicketCount: 10,
		maxTotalBet: 1000,
		maxMultiplierPerTicket: 1000000,
		maxWin: 100000,
	},
	dualSession: false,
	modals: {
		changeLog: false,
	},
	refs: {
		guideJackpotBlock: null,
	},
	ui: {
		historyActionFired: false,
	},
}

export default initialStore
