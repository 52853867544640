import st from './Header.module.scss'
import { Fragment, memo } from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import Jackpot from '../Jackpot/Jackpot'
import cx from 'classnames'
import Balance from './Balance'
import { callAuthWindow, callPostMessage } from '../../helpers/helpers'
import { PMEndpoints } from '../../store/types'
import { useTranslation } from 'react-i18next'
import { appExit } from '../../store/actions/apiActions'

const Header = memo(() => {
	const userdata = useSelector((state) => state?.userdata, shallowEqual)
	const jackpot = useSelector((state) => state?.jackpot, shallowEqual)
	const { t, i18n } = useTranslation()
	const dispatch = useDispatch()
	return (
		<div className={st.header}>
			<a
				href="/"
				className={cx(st.logo, { [st.global]: i18n.language !== 'ka' })}
			>
				Volt Logo
			</a>
			<a
				href="//www.lider-bet.com/ka/promotions/playvolt-2025"
				className={st.promoBanner}
				target={'_blank'}
				rel={'noreferrer'}
			>
				Volt promo
			</a>
			<div className={st.jackpots}>
				<div className={st.jackpotsWrapper}>
					{jackpot.data.map((jp) => (
						<Jackpot
							key={jp['ID']}
							id={jp['ID']}
							title={`${t(
								`translation:common.jackpots.${
									jackpot.types[jp['ID']]
								}`,
							)} ${t('translation:common.jackpots.common_name')}`}
							value={jp['AMOUNT'] / 100}
							digits={8}
						/>
					))}
				</div>
			</div>
			<div className={st.userPanel}>
				{userdata.auth ? (
					<Fragment>
						<div className={st.username}>{userdata.username}</div>
						<div className={st.pin}>{userdata.pin}</div>
						{userdata.balance.virtual > 0 && (
							<Balance
								title={t('translation:common.freespin')}
								value={userdata.balance.virtual || 0}
								type="virtual"
							/>
						)}
						<Balance
							title={t('common.balance')}
							value={userdata.balance.real || 0}
						/>
						<div
							className={st.btnCashier}
							onClick={(e) => {
								e.preventDefault()
								callPostMessage(PMEndpoints.CASHIER)
							}}
						>
							{t('common.cashier')}
						</div>
					</Fragment>
				) : (
					<div
						className={st.btnAuth}
						onClick={(e) => {
							e.preventDefault()
							callAuthWindow()
						}}
					>
						{t('common.authorization')}
					</div>
				)}
				<i
					className={cx('icon-close', st.btnMenu)}
					onClick={(e) => {
						e.preventDefault()
						dispatch(appExit())
					}}
				/>
			</div>
		</div>
	)
})

export default Header
