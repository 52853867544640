import { callAuthWindow, i18TagMap, toMoney } from '../../helpers/helpers'
import i18n from 'helpers/i18n'
import { Trans } from 'react-i18next'

export const gameStates = {
	ERROR: 'ERROR',
	NETWORK: 'NETWORK',
	ST_GO_GO: 'ST_GO_GO',
	ST_CRASH: 'ST_CRASH',
	ST_START_BET: 'ST_START_BET',
	ST_GO_START: 'ST_GO_START',
	ST_CREATE_EDITION: 'ST_CREATE_EDITION',
}

export const ticketStates = {
	TICKET_IDLE: 'BTN_STATE_IDLE',
	TICKET_SUBMITTED: 'BTN_STATE_SUBMITTED',
	TICKET_ACCEPTED: 'BTN_STATE_ACCEPTED',
}

export const eventTypes = {
	PLACE_BET: 'PLACE_BET',
	CASHOUT: 'CASHOUT',
	LOSS: 'LOSS',
	RECOVERY: 'RECOVERY',
}

export const basicTypes = {
	STORAGE_KEY: 'VOLT_CONFIG',
}

export const ErrorCodes = {
	DuplicateClientIdException: 'DuplicateClientIdException',
	WrongStateException: 'WrongStateException',
	GameSessionNotFound: 'GameSessionNotFound',
	SessionClosedException: 'SessionClosedException',
	BalanceNotEnough: 'BalanceNotEnough',
	TransactionBatchNotApplied: 'TransactionBatchNotApplied',
	TransactionAbortException: 'TransactionAbortException',
	BetValidationException: 'BetValidationException',
}

export const PMEndpoints = {
	AUTH: 'auth',
	CASHIER: 'cashier',
	FULLSCREEN: 'fullscreen',
	EXIT: 'exit',
}

export const ToastID = {
	SERVER_ERROR: 'SERVER_ERROR',
	MAX_TOTAL_BET: 'MAX_TOTAL_BET',
	MAX_TOTAL_BET_SINGLE: 'MAX_TOTAL_BET_SINGLE',
	BALANCE_NOT_ENOUGH: 'BALANCE_NOT_ENOUGH',
	BALANCE_NOT_ENOUGH_VIRTUAL: 'BALANCE_NOT_ENOUGH_VIRTUAL',
	GENERAL_ERROR: 'GENERAL_ERROR',
	MAX_TICKETS: 'MAX_TICKETS',
	MIN_BET_PER_TICKET: 'MIN_BET_PER_TICKET',
	AUTH_REQUIRED: 'AUTH_REQUIRED',
	NETWORK_OFFLINE: 'NETWORK_OFFLINE',
}

export const messages = {
	[ToastID.MAX_TOTAL_BET]: (maxBetLimit) => (
		<>
			<Trans
				i18nKey={'translation:messages.max_total_bet'}
				components={i18TagMap()}
				values={{
					maxBetLimit: toMoney(maxBetLimit),
				}}
			>
				ერთ ტირაჟზე განთავსებული სტანდარტული ბილეთების ჯამური ფსონი არ უნდა
				აღემატებოდეს
				<code>
					{toMoney(maxBetLimit)}
					<i className="icon-gel" />
				</code>
				-ს
			</Trans>
		</>
	),
	[ToastID.MAX_TOTAL_BET_SINGLE]: (maxBetLimit) => (
		<>
			<Trans
				i18nKey={'translation:messages.max_total_bet_single'}
				components={i18TagMap()}
				values={{
					maxBetLimit: toMoney(maxBetLimit),
				}}
			>
				სტანდარტული ბილეთის მაქსიმალური დასაშვები ფსონია{' '}
				<code>
					{toMoney(maxBetLimit)}
					<i className="icon-gel" />
				</code>
			</Trans>
		</>
	),
	[ToastID.GENERAL_ERROR]: () => (
		<>
			{i18n.t('translation:messages.generic_error.text')}
			<button
				className={'btn toast-err-btn'}
				onClick={(e) => {
					e.stopPropagation()
					e.preventDefault()
					window.location?.reload()
				}}
			>
				{i18n.t('translation:messages.generic_error.reload_page')}
			</button>
		</>
	),
	[ToastID.BALANCE_NOT_ENOUGH]: () => (
		<>{i18n.t('translation:messages.not_enough_money')}</>
	),
	[ToastID.BALANCE_NOT_ENOUGH_VIRTUAL]: () => (
		<>{i18n.t('translation:messages.not_enough_money_virtual')}</>
	),
	[ToastID.MAX_TICKETS]: (maxTickets) => (
		<>
			{i18n.t('translation:messages.max_tickets_limit', {
				maxTickets: maxTickets,
			})}
		</>
	),
	[ToastID.MIN_BET_PER_TICKET]: (minBet) => (
		<>
			<Trans
				i18nKey={'translation:messages.min_bet_per_ticket'}
				components={i18TagMap()}
				values={{
					minBet: toMoney(minBet),
				}}
			>
				ფსონი არ უნდა იყოს{' '}
				<code>
					{toMoney(minBet)}
					<i className="icon-gel" />
				</code>
				-ზე ნაკლები
			</Trans>
		</>
	),
	[ToastID.AUTH_REQUIRED]: () => (
		<>
			{i18n.t('translation:messages.toast.auth.text')}
			<button
				className={'btn btn-warning toast-warn-btn'}
				onClick={(e) => {
					e.stopPropagation()
					e.preventDefault()
					callAuthWindow()
				}}
			>
				{i18n.t('translation:common.authorization')}
			</button>
		</>
	),
	[ToastID.NETWORK_OFFLINE]: () => (
		<>{i18n.t('translation:messages.network_problem')}</>
	),
}

export const jackpotID = {
	KILOWATT: 2,
	MEGAWATT: 3,
	GIGAWATT: 4,
}
