import st from './Ticket.module.scss'
import PropTypes from 'prop-types'
import cx from 'classnames'
import {memo, useCallback, useEffect, useRef, useState} from 'react'
import { shallowEqual, useDispatch, useSelector } from 'react-redux'
import {
	cancelTicket,
	cashout,
	cleanTicketInputs,
	placeBet,
	removeTicket,
	setAutoBet,
	setAutoCashout,
	setTicketBet,
	setTicketCashout,
	submitBetShortcut,
	submitCashoutShortcut,
	submitTicket,
	tktStepChange,
} from '../../store/actions/ticketActions'
import ReactTooltip from 'react-tooltip'
import TicketButton from '../TicketButton/TicketButton'
import buttonTypes from '../TicketButton/buttonTypes'
import { ticketStates, gameStates } from '../../store/types'
import { callAuthWindow } from '../../helpers/helpers'
import { useTranslation } from 'react-i18next'
import log from 'loglevel'

const Ticket = memo(({ data, type }) => {
    const shortcuts = useSelector((state) => state?.shortcuts, shallowEqual)
    const gameState = useSelector((state) => state?.gameState.state)
    const dispatch = useDispatch()
    const [inputFocus, setInputFocus] = useState({
        betInput: false,
        cashoutInput: false,
    })
    const betRef = useRef()
    const cashoutRef = useRef()
    const auth = useSelector((state) => state?.userdata.auth)
    const { t } = useTranslation()
    const intervalRef = useRef(null)

    const getButtonType = useCallback(
        (state) => {
            switch (state) {
                case ticketStates.TICKET_SUBMITTED:
                    if (gameState === gameStates.ST_START_BET) {
                        return buttonTypes.BET_LOADER
                    } else {
                        return buttonTypes.CANCEL_BET
                    }
                case ticketStates.TICKET_ACCEPTED:
                    switch (gameState) {
                        case gameStates.ST_GO_GO:
                            return buttonTypes.BET_CASHOUT
                        default:
                            return buttonTypes.BET_ACCEPTED
                    }
                default:
                    return buttonTypes.PLACE_BET
            }
        },
        [gameState],
    )
    const startStepInterval = useCallback(
        (options) => {
            if (intervalRef.current || typeof options === 'undefined') return
            intervalRef.current = setInterval(() => {
                dispatch(tktStepChange(options))
            }, 130)
        },
        [dispatch],
    )
    const stopStepInterval = useCallback(() => {
        if (intervalRef.current) {
            clearInterval(intervalRef.current)
            intervalRef.current = null
        }
    }, [])
    const tktBtnCallback = useCallback(() => {
        switch (data.state) {
            case ticketStates.TICKET_IDLE:
                // check if authorized
                if (auth) {
                    if (gameState === gameStates.ST_START_BET) {
                        dispatch(placeBet(data))
                    } else {
                        dispatch(submitTicket(data))
                    }
                } else {
                    callAuthWindow()
                }
                break
            case ticketStates.TICKET_SUBMITTED:
                dispatch(cancelTicket(data))
                break
            case ticketStates.TICKET_ACCEPTED:
                if (gameState === gameStates.ST_GO_GO) {
                    dispatch(cashout([data.sid]))
                }
                break
            default:
                log.error('Something went wrong. Check ticket button actions...')
                break
        }
    }, [auth, data, dispatch, gameState])

    useEffect(() => {
        if (inputFocus.betInput) {
            betRef.current?.focus()
            betRef.current?.select()
        }
        if (inputFocus.cashoutInput) {
            cashoutRef.current?.focus()
            cashoutRef.current?.select()
        }

        if (inputFocus.betInput || inputFocus.cashoutInput) {
            // alert(`focus: ${inputFocus.betInput ? 'true' : 'false'} | ${inputFocus.cashoutInput ? 'true' : 'false'}`)
        }

        // clean inputs
        dispatch(cleanTicketInputs({ id: data.id }))

        // clear interval on unmount
        return () => stopStepInterval()
    }, [
        data.id,
        dispatch,
        inputFocus.betInput,
        inputFocus.cashoutInput,
        stopStepInterval,
    ])

    return (
        <div
            className={cx(st.ticket, {
                [st.locked]: data.state !== ticketStates.TICKET_IDLE,
                [st.virtual]: !data.std,
                [st.mob]: type === 'mob',
            })}
        >
            <div className={st.inputs}>
                <div
                    className={cx(st.inputGroup, st.betGroup, {
                        [st.focused]: inputFocus.betInput,
                    })}
                    onClick={(e) => {
                        e.stopPropagation()
                        e.preventDefault()
                        setInputFocus({ betInput: true, cashoutInput: false })

                        // ios fix
                        betRef.current?.focus()
                        betRef.current?.select()
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <i
                        className={cx('icon-decrease', st.decBtn)}
                        onClick={() =>
                            dispatch(
                                tktStepChange({
                                    id: data.id,
                                    type: 'bet',
                                    action: 'dec',
                                }),
                            )
                        }
                        onMouseDown={() =>
                            startStepInterval({
                                id: data.id,
                                type: 'bet',
                                action: 'dec',
                            })
                        }
                        onMouseLeave={stopStepInterval}
                        onMouseUp={stopStepInterval}
                    />

                    <div className={st.label}>{t('translation:common.bet')}</div>
                    <input
                        className={st.input}
                        ref={betRef}
                        type={type === 'mob' ? 'number' : 'text'}
                        inputMode="decimal"
                        name="bet"
                        autoComplete="off"
                        value={data.bet}
                        onFocus={() =>
                            setInputFocus({ betInput: true, cashoutInput: false })
                        }
                        onBlur={() =>
                            setInputFocus((prevState) => ({
                                cashoutInput: prevState.cashoutInput,
                                betInput: false,
                            }))
                        }
                        onChange={(e) =>
                            dispatch(
                                setTicketBet({
                                    id: data.id,
                                    bet: e.target.value,
                                }),
                            )
                        }
                    />
                    <i className="icon-gel" />
                    <i
                        className={cx('icon-add', st.incBtn)}
                        onClick={() =>
                            dispatch(
                                tktStepChange({
                                    id: data.id,
                                    type: 'bet',
                                    action: 'inc',
                                }),
                            )
                        }
                        onMouseDown={() =>
                            startStepInterval({
                                id: data.id,
                                type: 'bet',
                                action: 'inc',
                            })
                        }
                        onMouseLeave={stopStepInterval}
                        onMouseUp={stopStepInterval}
                    />
                    <div
                        className={cx(st.toggleBtn, st.autoBetBtn, {
                            [st.on]: data.autoBet,
                        })}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            dispatch(
                                setAutoBet({ id: data.id, autoBet: !data.autoBet }),
                            )
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        data-tip={t('translation:common.auto_bet')}
                        data-for="autoBetTooltip"
                    />
                    <ReactTooltip
                        id={'autoBetTooltip'}
                        place={'top'}
                        effect={'solid'}
                        offset={{ top: -5 }}
                        backgroundColor={'rgba(0, 0, 0, .6)'}
                        className={st.toolTip}
                        delayShow={600}
                    />
                </div>
                <div
                    className={cx(st.inputGroup, st.cashoutGroup, {
                        [st.focused]: inputFocus.cashoutInput,
                        [st.autoCashoutOff]: !data.autoCashout,
                    })}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                        setInputFocus({ betInput: false, cashoutInput: true })

                        // ios fix
                        cashoutRef.current?.focus()
                        cashoutRef.current?.select()
                    }}
                    onMouseDown={(e) => e.preventDefault()}
                >
                    <i
                        className={cx('icon-decrease', st.decBtn)}
                        onClick={() =>
                            dispatch(
                                tktStepChange({
                                    id: data.id,
                                    type: 'cashout',
                                    action: 'dec',
                                }),
                            )
                        }
                        onMouseDown={() =>
                            startStepInterval({
                                id: data.id,
                                type: 'cashout',
                                action: 'dec',
                            })
                        }
                        onMouseLeave={stopStepInterval}
                        onMouseUp={stopStepInterval}
                    />
                    <div className={st.label}>{t('translation:common.cashout')}</div>
                    <input
                        className={st.input}
                        ref={cashoutRef}
                        type={type === 'mob' ? 'number' : 'text'}
                        inputMode="decimal"
                        name="cashout"
                        autoComplete="off"
                        value={data.cashout}
                        onFocus={() =>
                            setInputFocus({ betInput: false, cashoutInput: true })
                        }
                        onBlur={() =>
                            setInputFocus((prevState) => ({
                                ...prevState,
                                cashoutInput: false,
                            }))
                        }
                        onChange={(e) =>
                            dispatch(
                                setTicketCashout({
                                    id: data.id,
                                    cashout: e.target.value,
                                }),
                            )
                        }
                    />
                    <i className="icon-volt" />
                    <i
                        className={cx('icon-add', st.incBtn)}
                        onClick={() =>
                            dispatch(
                                tktStepChange({
                                    id: data.id,
                                    type: 'cashout',
                                    action: 'inc',
                                }),
                            )
                        }
                        onMouseDown={() =>
                            startStepInterval({
                                id: data.id,
                                type: 'cashout',
                                action: 'inc',
                            })
                        }
                        onMouseLeave={stopStepInterval}
                        onMouseUp={stopStepInterval}
                    />
                    <div
                        className={cx(st.toggleBtn, { [st.on]: data.autoCashout })}
                        onClick={(e) => {
                            e.preventDefault()
                            e.stopPropagation()
                            if (!data.autoCashout) {
                                setInputFocus({ betInput: false, cashoutInput: true })
                            }
                            dispatch(
                                setAutoCashout({
                                    id: data.id,
                                    autoCashout: !data.autoCashout,
                                }),
                            )
                        }}
                        onMouseDown={(e) => e.preventDefault()}
                        data-tip={t('translation:common.auto_cashout')}
                        data-for="autCashoutTooltip"
                    />
                    <ReactTooltip
                        id={'autCashoutTooltip'}
                        place={'top'}
                        effect={'solid'}
                        offset={{ top: -5 }}
                        backgroundColor={'rgba(0, 0, 0, .8)'}
                        className={st.toolTip}
                        delayShow={600}
                    />
                </div>
            </div>
            <div className={st.errors}>
                {/*	<div className={st.errorMsg}>
					<div className={st.icon}>
						<i className={'icon-attention'} />
					</div>
					<div className={st.text}>
						არასაკმარისი ბალანსი
					</div>
				</div>*/}
            </div>
            <div className={st.shortcuts}>
                <div
                    className={cx(st.betShortcutsGroup, {
                        [st.active]: !inputFocus.cashoutInput,
                    })}
                >
                    {shortcuts.bet.map((btn, ix) => (
                        <div
                            key={ix}
                            className={cx(st.btn, st.betShortcut, {
                                [st.x2]: btn === 'x2',
                                [st.allin]: btn === 'all',
                            })}
                            onClick={(e) => {
                                e.preventDefault()
                                e.stopPropagation()
                                if (!inputFocus.betInput) {
                                    setInputFocus({
                                        betInput: true,
                                        cashoutInput: false,
                                    })
                                }
                                dispatch(submitBetShortcut({ id: data.id, bet: btn }))
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            {typeof btn === 'number' ? (
                                <>
                                    {btn.toFixed(2)} <i className="icon-gel" />
                                </>
                            ) : btn === 'all' ? (
                                'All-in'
                            ) : (
                                btn
                            )}
                        </div>
                    ))}
                </div>
                <div
                    className={cx(st.cashoutShortcutsGroup, {
                        [st.active]: inputFocus.cashoutInput,
                    })}
                    onClick={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                    onMouseDown={(e) => {
                        e.preventDefault()
                        e.stopPropagation()
                    }}
                >
                    {shortcuts.cashout.map((btn, ix) => (
                        <div
                            key={ix}
                            className={cx(st.btn, st.cashoutShortcut)}
                            onClick={(e) => {
                                e.stopPropagation()
                                e.preventDefault()
                                if (!inputFocus.cashoutInput) {
                                    setInputFocus({
                                        betInput: false,
                                        cashoutInput: true,
                                    })
                                }
                                dispatch(
                                    submitCashoutShortcut({ id: data.id, cashout: btn }),
                                )
                            }}
                            onMouseDown={(e) => e.preventDefault()}
                        >
                            {btn}
                            <i className="icon-volt" />
                        </div>
                    ))}
                </div>
            </div>
            <div className={cx(st.actionButtons, { [st.mobile]: type === 'mob' })}>
                <TicketButton
                    type={getButtonType(data.state)}
                    cashoutValue={20}
                    removeAction={() => dispatch(removeTicket({ id: data.id }))}
                    bet={parseFloat(data.bet)}
                    virtual={!data.std}
                    onClick={tktBtnCallback}
                    mobile={type === 'mob'}
                />
            </div>
        </div>
    )
})

Ticket.displayName = 'Ticket'

Ticket.propTypes = {
	data: PropTypes.shape({
		id: PropTypes.string.isRequired,
		sid: PropTypes.string,
		std: PropTypes.bool.isRequired,
		bet: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
		autoBet: PropTypes.bool.isRequired,
		cashout: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
			.isRequired,
		autoCashout: PropTypes.bool.isRequired,
		minCashout: PropTypes.number.isRequired,
		state: PropTypes.oneOf([...Object.values(ticketStates)]),
	}).isRequired,
	type: PropTypes.oneOf(['mob', 'web']),
}

Ticket.defaultProps = {
	type: 'web',
}

export default Ticket
