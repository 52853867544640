import actionTypes from '../types/actionTypes'

const userDataReducer = {
	[actionTypes.SET_USER_DATA]: (state, action) => {
		state.userdata = {
			...state.userdata,
			...action.payload,
		}
	},
}

export default userDataReducer
